<template>
  <UiComponentWrapper class="page">
    <!-- Modal > Video -->
    <Transition
      @enter="onEnter"
      @leave="onLeave"
    >
      <UiComponentModal
        v-if="videoIsDisplayed"
        class="modal"
        @modal:close="closeModal"
      >
        <template #content>
          <OrganismPlayerVideo
            initialize-immediately
            :poster-src="`${data.page.heroFullVideo.asset.thumbnailUrl}?w=${Math.min(Math.max(screenWidth, 0), 767)}&h=auto&dpr=${dpr}&fm=png&auto=format`"
            :video-src="data.page.heroFullVideo.asset.streamingUrl"
          />
        </template>
      </UiComponentModal>
    </Transition>

    <!-- Section > Hero -->
    <UiComponentContainer class="hero">
      <UiComponentHeading>{{ data.page.heroTitle }}</UiComponentHeading>
      <UiComponentParagraph>{{ data.page.heroText }}</UiComponentParagraph>
      <UiComponentWrapper class="actions">
        <AtomButton
          :action="signupOrUpgradeCallToAction.action"
          label="Header section CTA"
          size="l"
          :to="signupOrUpgradeCallToAction.to"
        >
          {{ signupOrUpgradeCallToAction.slot }}
        </AtomButton>
        <AtomButton
          :action="requestDemoCallToAction.action"
          label="Header section CTA"
          size="l"
          :to="requestDemoCallToAction.to"
          type="secondary"
        >
          {{ requestDemoCallToAction.slot }}
        </AtomButton>
      </UiComponentWrapper>
      <UiComponentWrapper class="customers">
        <UiComponentWrapper
          v-for="{ id, logo } in customers"
          :key="`logo-${id}`"
          class="logo"
        >
          <UiComponentImage
            :alt="logo.alt"
            :expected-size="20"
            :extra-params="{
              con: 100,
              gam: 100,
              monochrome: '000000',
              sat: -100,
              trim: 'color',
              vib: 100,
            }"
            :height="logo.height"
            :orientation="logo.width >= logo.height ? 'portrait' : 'landscape'"
            :source="logo.url"
            use-natural-height
            use-natural-width
            :width="logo.width"
          />
        </UiComponentWrapper>
      </UiComponentWrapper>
      <UiComponentWrapper class="assets">
        <UiComponentWrapper class="integrations">
          <UiComponentWrapper
            v-for="{ id, backgroundColor: bgColor, logo } in data.page.heroIntegrations"
            :key="`integration-${id}`"
            class="integration"
            :style="{
              '--bg-color': `${bgColor.red}, ${bgColor.green}, ${bgColor.blue}`,
            }"
          >
            <UiComponentImage
              v-if="logo"
              :alt="logo.alt"
              class="logo"
              :height="logo.height"
              :expected-size="40"
              :extra-params="{
                trim: 'color',
                w: 40,
              }"
              :source="logo.url"
              use-natural-height
              use-natural-width
              :width="logo.width"
            />
          </UiComponentWrapper>
        </UiComponentWrapper>
        <UiComponentWrapper class="badges-and-polls">
          <UiComponentWrapper class="badges">
            <UiComponentImage
              v-if="data.page.heroGdpr.badge"
              :alt="data.page.heroGdpr.badge.alt"
              :expected-size="64"
              :height="data.page.heroGdpr.badge.height"
              :source="data.page.heroGdpr.badge.url"
              :width="data.page.heroGdpr.badge.width"
            />
            <UiComponentImage
              v-if="data.page.heroIso27001.badge"
              :alt="data.page.heroIso27001.badge.alt"
              :expected-size="64"
              :height="data.page.heroIso27001.badge.height"
              :source="data.page.heroIso27001.badge.url"
              :width="data.page.heroIso27001.badge.width"
            />
          </UiComponentWrapper>
          <UiComponentWrapper class="polls">
            <UiComponentWrapper
              v-for="{ id, question, answers } in data.page.heroPolls"
              :key="`poll-${id}`"
              class="poll"
            >
              <UiComponentParagraph class="title">
                {{ question }}
              </UiComponentParagraph>
              <UiComponentWrapper
                v-for="answer in answers"
                :key="`poll-answer-${answer.id}`"
                class="answer"
              >
                <UiComponentWrapper
                  class="bar"
                  :style="{ width: `${answer.percentage}%` }"
                />
                <UiComponentParagraph class="value">
                  {{ answer.answer }}
                </UiComponentParagraph>
                <UiComponentParagraph class="count">
                  <span>{{ answer.percentage }}%</span>
                </UiComponentParagraph>
              </UiComponentWrapper>
            </UiComponentWrapper>
          </UiComponentWrapper>
        </UiComponentWrapper>
        <UiComponentWrapper class="video">
          <UiComponentVideo
            :autoplay="false"
            :height="data.page.heroShortVideo.height"
            loop
            muted
            :poster="data.page.heroShortVideo.asset.thumbnailUrl"
            :url="data.page.heroShortVideo.asset.mp4Url"
            :width="data.page.heroShortVideo.width"
          />
          <AtomButton @click="openModal">
            <UiComponentIcon
              theme="dark"
              type="play"
            />
          </AtomButton>
        </UiComponentWrapper>
        <UiComponentWrapper
          v-for="{ id, title, questions, textareaPlaceholder } in data.page.heroQuestions"
          :key="`questions-${id}`"
          class="questions"
        >
          <UiComponentWrapper class="head">
            <UiComponentHeading
              as="paragraph"
              :level="6"
            >
              {{ title }}
            </UiComponentHeading>
          </UiComponentWrapper>
          <UiComponentWrapper class="body">
            <UiComponentWrapper
              v-for="{ id: questionId, author, question, answer, upvotes } in questions"
              :key="`questions-question-${questionId}`"
              class="question"
            >
              <UiComponentWrapper class="inner">
                <UiComponentWrapper class="upvotes">
                  <UiComponentWrapper class="up">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="4"
                      height="3"
                      viewBox="0 0 4 3"
                      fill="none"
                    >
                      <path
                        d="M1.90118 0.481018L3.56408 2.97537H0.238281L1.90118 0.481018Z"
                        fill="#232B2F"
                      />
                    </svg>
                  </UiComponentWrapper>
                  <UiComponentParagraph>{{ upvotes }}</UiComponentParagraph>
                </UiComponentWrapper>
                <UiComponentWrapper class="picture">
                  <UiComponentImage
                    v-if="author[0].picture"
                    :alt="author[0].picture.alt"
                    :expected-size="questionsTabAvatarSize"
                    :extra-params="{
                      fit: 'crop',
                      h: questionsTabAvatarSize,
                      w: questionsTabAvatarSize,
                    }"
                    :height="author[0].picture.height"
                    :source="author[0].picture.url"
                    :width="author[0].picture.width"
                  />
                </UiComponentWrapper>
                <UiComponentWrapper class="message">
                  <UiComponentParagraph>{{ author[0].username }}</UiComponentParagraph>
                  <UiComponentParagraph>{{ question }}</UiComponentParagraph>
                </UiComponentWrapper>
              </UiComponentWrapper>
              <UiComponentWrapper
                v-if="answer.length > 0"
                class="inner answer"
              >
                <UiComponentImage
                  v-if="answer[0].author[0].picture"
                  :alt="answer[0].author[0].picture.alt"
                  class="avatar"
                  :expected-size="questionsTabAnswerAvatarSize"
                  :extra-params="{
                    fit: 'crop',
                    h: questionsTabAnswerAvatarSize,
                    w: questionsTabAnswerAvatarSize,
                  }"
                  :height="answer[0].author[0].picture.height"
                  :source="answer[0].author[0].picture.url"
                  :width="answer[0].author[0].picture.width"
                />
                <UiComponentParagraph>{{ answer[0].value }}</UiComponentParagraph>
              </UiComponentWrapper>
            </UiComponentWrapper>
          </UiComponentWrapper>
          <UiComponentWrapper class="footer">
            <UiComponentTextarea
              id="reply"
              :content="textareaPlaceholder"
              disabled
              rows="1"
            />
          </UiComponentWrapper>
        </UiComponentWrapper>
        <UiComponentWrapper class="reviews">
          <UiComponentWrapper class="head">
            <UiComponentWrapper class="stars">
              <UiComponentWrapper
                v-for="n in 5"
                :key="`star-${n}`"
                class="star"
                :style="{
                  '--rating': data.page.heroG2Rating - n < 0 ? 1 - Math.abs(data.page.heroG2Rating - n) : 1,
                }"
              />
            </UiComponentWrapper>
            <div
              class="text"
              v-html="data.componentHeroReviews.numbersOfReviews"
            />
          </UiComponentWrapper>
          <UiComponentWrapper class="body">
            <UiComponentImage
              v-if="data.page.heroG2Badge"
              :alt="data.page.heroG2Badge.medal.alt"
              class="medal"
              :expected-size="89.5"
              :height="data.page.heroG2Badge.medal.height"
              :orientation="data.page.heroG2Badge.medal.width >= data.page.heroG2Badge.medal.height ? 'portrait' : 'landscape'"
              :source="data.page.heroG2Badge.medal.url"
              :width="data.page.heroG2Badge.medal.width"
            />
          </UiComponentWrapper>
        </UiComponentWrapper>
      </UiComponentWrapper>
    </UiComponentContainer>

    <!-- Section > Benefits -->
    <UiComponentContainer class="benefits">
      <UiComponentHeading :level="2">
        {{ data.page.benefitsTitle }}
      </UiComponentHeading>
      <UiComponentWrapper class="list">
        <UiComponentWrapper
          v-for="{ id, image, title, text } in data.page.benefits"
          :key="`benefit-${id}`"
          class="benefit"
        >
          <UiComponentImage
            :alt="image.alt"
            :expected-size="72"
            :height="image.height"
            :source="image.url"
            :width="image.width"
          />
          <UiComponentHeading :level="3">
            {{ title }}
          </UiComponentHeading>
          <UiComponentParagraph>{{ text }}</UiComponentParagraph>
        </UiComponentWrapper>
      </UiComponentWrapper>
    </UiComponentContainer>

    <!-- Section > Industries -->
    <UiComponentContainer class="industries">
      <UiComponentWrapper class="inner">
        <UiComponentWrapper class="head">
          <UiComponentHeading :level="2">
            {{ data.page.industriesTitle }}
          </UiComponentHeading>
          <UiComponentWrapper class="menu">
            <UiComponentWrapper
              v-for="({ id, industry }, index) in data.page.industriesHighlightedItems"
              :key="`head-item-${id}`"
              class="item"
              :class="[
                { active: industriesActiveIndex === index },
                industry.theme.color,
              ]"
              @click="setIndustriesActiveIndex(index)"
            >
              <UiComponentParagraph>{{ industry.shortName }}</UiComponentParagraph>
            </UiComponentWrapper>
            <AtomButton
              class="action"
              size="l"
              :to="localePath('industries')"
              type="text"
            >
              {{ data.page.industriesCtaWording }}
              <UiComponentIcon type="arrow-right" />
            </AtomButton>
          </UiComponentWrapper>
        </UiComponentWrapper>
        <UiComponentWrapper class="body">
          <UiComponentWrapper
            class="slider"
            :style="{
              transform: `translateX(-${industriesActiveIndex * industriesSectionSize}px)`,
              width: `${industriesSectionSize * 3}px`,
            }"
          >
            <UiComponentWrapper
              v-for="{ id, industry, image, title, testimonial, keyNumbers } in data.page.industriesHighlightedItems"
              :key="`body-item-${id}`"
              class="item"
              :class="[
                industry.theme.color,
                { full: keyNumbers.filter((keyNumber) => !!keyNumber.source.value || !!keyNumber.value).length === 0 },
              ]"
              :style="{ width: `${industriesSectionSize}px` }"
            >
              <UiComponentWrapper class="main">
                <UiComponentWrapper class="illustation">
                  <UiComponentImage
                    :key="`industry-image-${industryImageSize}`"
                    :alt="image.alt"
                    :expected-size="industryImageSize"
                    :height="image.height"
                    :source="image.url"
                    :width="image.width"
                  />
                </UiComponentWrapper>
                <UiComponentWrapper class="content">
                  <NuxtLink
                    class="title"
                    :to="
                      localePath({
                        name: 'industries-slug',
                        params: {
                          slug: industry.slug,
                        },
                      })
                    "
                  >
                    <UiComponentHeading :level="3">
                      {{ title }}
                    </UiComponentHeading>
                  </NuxtLink>
                  <UiComponentParagraph class="quote">
                    {{ testimonial.quote }}
                  </UiComponentParagraph>
                  <UiComponentWrapper class="metadata">
                    <UiComponentWrapper class="author">
                      <UiComponentImage
                        :alt="testimonial.profilePicture.alt"
                        class="picture"
                        :expected-size="44"
                        :extra-params="{
                          fit: 'crop',
                          h: 44,
                          w: 44,
                        }"
                        :height="testimonial.profilePicture.height"
                        :source="testimonial.profilePicture.url"
                        use-natural-height
                        use-natural-width
                        :width="testimonial.profilePicture.width"
                      />
                      <UiComponentWrapper class="name">
                        <UiComponentParagraph>{{ testimonial.author }}</UiComponentParagraph>
                        <UiComponentParagraph>{{ testimonial.job }}</UiComponentParagraph>
                      </UiComponentWrapper>
                    </UiComponentWrapper>
                    <UiComponentImage
                      :alt="testimonial.logo.alt"
                      class="logo"
                      :expected-size="20"
                      :extra-params="{
                        trim: 'color',
                      }"
                      :height="testimonial.logo.height"
                      orientation="portrait"
                      :source="testimonial.logo.url"
                      use-natural-height
                      use-natural-width
                      :width="testimonial.logo.width"
                    />
                  </UiComponentWrapper>
                  <AtomButton
                    type="text"
                    @click="
                      push(
                        localePath({
                          name: 'industries-slug',
                          params: {
                            slug: industry.slug,
                          },
                        }),
                      )
                    "
                  >
                    {{ data.componentGlobal.learnMore }}
                    <UiComponentIcon type="arrow-right" />
                  </AtomButton>
                </UiComponentWrapper>
              </UiComponentWrapper>
              <UiComponentWrapper
                v-if="keyNumbers.filter((keyNumber) => !!keyNumber.source.value || !!keyNumber.value).length > 0"
                class="aside"
              >
                <UiComponentWrapper
                  v-for="keyNumber in keyNumbers.filter((keyNumber) => !!keyNumber.source.value || !!keyNumber.value)"
                  :key="`key-number-${keyNumber}`"
                  class="key-number"
                >
                  <UiComponentParagraph>{{ getTitleFromKeyNumber(keyNumber.value || keyNumber.source.value) }}</UiComponentParagraph>
                  <UiComponentParagraph>{{ getDescriptionFromKeyNumber(keyNumber.value || keyNumber.source.value) }}</UiComponentParagraph>
                  <UiComponentImage
                    :alt="keyNumber.source.logo.alt"
                    class="logo"
                    :expected-size="20"
                    :extra-params="{
                      trim: 'color',
                    }"
                    :height="keyNumber.source.logo.height"
                    :orientation="keyNumber.source.logo.width >= keyNumber.source.logo.height ? 'portrait' : 'landscape'"
                    :source="keyNumber.source.logo.url"
                    use-natural-height
                    use-natural-width
                    :width="keyNumber.source.logo.width"
                  />
                </UiComponentWrapper>
              </UiComponentWrapper>
            </UiComponentWrapper>
          </UiComponentWrapper>
        </UiComponentWrapper>
      </UiComponentWrapper>
    </UiComponentContainer>

    <!-- Section > Use Cases -->
    <UiComponentContainer
      class="use-cases"
      data-back="#FFFFFF"
      data-enter="#F6F7F9"
      data-start="top top"
      data-end="bottom bottom"
    >
      <UiComponentHeading :level="2">
        {{ data.page.useCasesTitle }}
      </UiComponentHeading>
      <UiComponentParagraph>{{ data.page.useCasesText }}</UiComponentParagraph>
      <UiComponentWrapper class="actions">
        <AtomButton
          :action="signupOrUpgradeCallToAction.action"
          label="Use cases section CTA"
          size="l"
          :to="signupOrUpgradeCallToAction.to"
        >
          {{ signupOrUpgradeCallToAction.slot }}
        </AtomButton>
        <AtomButton
          :action="requestDemoCallToAction.action"
          label="Use cases section CTA"
          size="l"
          :to="requestDemoCallToAction.to"
          type="secondary"
        >
          {{ requestDemoCallToAction.slot }}
        </AtomButton>
      </UiComponentWrapper>
      <UiComponentWrapper class="menu">
        <AtomAnchor
          v-for="({ image, title, description, useCase }, index) in data.page.useCasesHighlightedItems"
          class="item"
          :class="{ active: useCasesActiveIndex === index }"
          :to="
            useCase
              ? localePath({
                name: 'use-cases-slug',
                params: {
                  slug: useCase.slug,
                },
              })
              : undefined
          "
          @mouseenter="setUseCasesActiveIndex(index)"
        >
          <UiComponentImage
            :key="`use-case-image-${useCaseImageSize}`"
            :alt="image.alt"
            :expected-size="useCaseImageSize"
            :extra-params="useCaseImageParams"
            :height="image.height"
            :source="image.url"
            use-natural-height
            use-natural-width
            :width="image.width"
          />
          <UiComponentWrapper class="head">
            <UiComponentHeading :level="3">
              {{ title || (useCase && useCase.shortName) }}
            </UiComponentHeading>
          </UiComponentWrapper>
          <UiComponentWrapper class="body">
            <UiComponentWrapper class="inner">
              <UiComponentParagraph>{{ title || (useCase && useCase.shortName) }}</UiComponentParagraph>
              <UiComponentParagraph>{{ description || (useCase && useCase.description) }}</UiComponentParagraph>
            </UiComponentWrapper>
            <UiComponentWrapper
              v-if="useCase"
              class="button"
            >
              <UiComponentIcon type="arrow-right" />
            </UiComponentWrapper>
          </UiComponentWrapper>
        </AtomAnchor>
      </UiComponentWrapper>
      <UiComponentWrapper class="action">
        <AtomButton
          size="l"
          :to="localePath('use-cases')"
          type="text"
        >
          {{ isDesktop ? data.page.useCasesDesktopCtaWording : data.page.useCasesMobileCtaWording }}
          <UiComponentIcon type="arrow-right" />
        </AtomButton>
      </UiComponentWrapper>
    </UiComponentContainer>

    <!-- Section > Showcased Features -->
    <UiComponentContainer class="showcased-features">
      <UiComponentHeading :level="2">
        {{ data.page.showcasedFeaturesTitle }}
      </UiComponentHeading>
      <UiComponentParagraph>{{ data.page.showcasedFeaturesText }}</UiComponentParagraph>
      <UiComponentWrapper class="actions">
        <AtomButton
          :action="signupOrUpgradeCallToAction.action"
          label="Features section CTA"
          size="l"
          :to="signupOrUpgradeCallToAction.to"
        >
          {{ signupOrUpgradeCallToAction.slot }}
        </AtomButton>
        <AtomButton
          :action="requestDemoCallToAction.action"
          label="Features section CTA"
          size="l"
          :to="requestDemoCallToAction.to"
          type="secondary"
        >
          {{ requestDemoCallToAction.slot }}
        </AtomButton>
      </UiComponentWrapper>
      <UiComponentWrapper class="list">
        <UiComponentWrapper class="outer">
          <!-- Card > Registration & Company Page -->
          <UiComponentWrapper
            class="item registration-and-company-page"
            data-speed="-0.6"
            @mousemove="onMouseMove($event, 'showcased-features')"
          >
            <UiComponentWrapper class="inner">
              <UiComponentWrapper class="neutral">
                <UiComponentHeading
                  as="paragraph"
                  :level="5"
                >
                  {{ data.page.showcasedFeaturesRegistrationAndCompanyPageTitle }}
                </UiComponentHeading>
                <UiComponentImage
                  v-if="data.page.showcasedFeaturesRegistrationAndCompanyPageNeutralBackground"
                  :key="`neutral-background-${registrationAndCompanyPageImageSize}`"
                  class="background"
                  :alt="data.page.showcasedFeaturesRegistrationAndCompanyPageNeutralBackground.alt"
                  :expected-size="registrationAndCompanyPageImageSize"
                  :height="data.page.showcasedFeaturesRegistrationAndCompanyPageNeutralBackground.height"
                  :source="data.page.showcasedFeaturesRegistrationAndCompanyPageNeutralBackground.url"
                  :width="data.page.showcasedFeaturesRegistrationAndCompanyPageNeutralBackground.width"
                />
              </UiComponentWrapper>
              <UiComponentWrapper class="branded">
                <UiComponentHeading
                  as="paragraph"
                  :level="5"
                >
                  {{ data.page.showcasedFeaturesRegistrationAndCompanyPageTitle }}
                </UiComponentHeading>
                <UiComponentImage
                  v-if="data.page.showcasedFeaturesRegistrationAndCompanyPageBrandedBackground"
                  :key="`neutral-background-${registrationAndCompanyPageImageSize}`"
                  :alt="data.page.showcasedFeaturesRegistrationAndCompanyPageBrandedBackground.alt"
                  class="background"
                  :expected-size="registrationAndCompanyPageImageSize"
                  :height="data.page.showcasedFeaturesRegistrationAndCompanyPageBrandedBackground.height"
                  :source="data.page.showcasedFeaturesRegistrationAndCompanyPageBrandedBackground.url"
                  :width="data.page.showcasedFeaturesRegistrationAndCompanyPageBrandedBackground.width"
                />
              </UiComponentWrapper>
            </UiComponentWrapper>
          </UiComponentWrapper>

          <!-- Card > AI Transcription  -->
          <UiComponentWrapper
            class="item ai-transcription"
            data-speed="-0.6"
            @mouseenter="onMouseEnter('ai-transcription')"
            @mouseleave="onMouseLeave('ai-transcription')"
          >
            <UiComponentWrapper class="inner">
              <UiComponentWrapper class="body">
                <UiComponentWrapper class="thread">
                  <UiComponentWrapper
                    v-for="{ id, author, content } in data.page.showcasedFeaturesAiTranscriptions"
                    :key="`transcription-${id}`"
                    class="post"
                  >
                    <UiComponentImage
                      :alt="author[0].profilePicture.alt"
                      class="avatar"
                      :expected-size="24"
                      :extra-params="{
                        fit: 'crop',
                        h: 24,
                        w: 24,
                      }"
                      :height="author[0].profilePicture.height"
                      :source="author[0].profilePicture.url"
                      use-natural-height
                      use-natural-width
                      :width="author[0].profilePicture.width"
                    />
                    <UiComponentWrapper class="content">
                      <UiComponentParagraph>{{ author[0].username }}</UiComponentParagraph>
                      <UiComponentParagraph>{{ content }}</UiComponentParagraph>
                    </UiComponentWrapper>
                  </UiComponentWrapper>
                </UiComponentWrapper>
              </UiComponentWrapper>
              <UiComponentWrapper class="footer">
                <UiComponentHeading
                  as="paragraph"
                  :level="5"
                >
                  {{ data.page.showcasedFeaturesAiTranscriptionTitle }}
                </UiComponentHeading>
                <UiComponentWrapper class="equalizer">
                  <UiComponentWrapper>
                    <UiComponentWrapper
                      v-for="bar in 10"
                      :key="`bar-${bar}`"
                      class="bar"
                      :class="`bar-${bar}`"
                    >
                      <UiComponentWrapper
                        v-for="line in 5"
                        :key="`bar-${bar}-line-${line}`"
                        class="line"
                        :class="`line-${line}`"
                      />
                    </UiComponentWrapper>
                  </UiComponentWrapper>
                  <UiComponentWrapper class="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="33"
                      height="33"
                      viewBox="0 0 33 33"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M6.55664 18.829C13.8096 18.8581 19.6876 24.7155 19.75 31.9606C19.8123 24.7123 25.6956 18.8529 32.9529 18.829C25.6961 18.8051 19.8131 12.9464 19.75 5.69885C19.6869 12.9433 13.8091 18.8 6.55664 18.829Z"
                        fill="#FAC9B8"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9.69531 5.06946C11.8993 5.07989 13.6827 6.86975 13.6827 9.07615V9.43767H13.683V9.07615C13.683 6.86807 15.4691 5.07716 17.6754 5.06945C15.4691 5.06173 13.683 3.27082 13.683 1.06274V0.701294H13.6827V1.06278C13.6827 3.26918 11.8993 5.05904 9.69531 5.06946Z"
                        fill="#FAC9B8"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.515625 11.7327H1.00217C3.93632 11.7327 6.3219 14.0814 6.38141 17.0013C6.44092 14.0814 8.8265 11.7327 11.7607 11.7327H12.2472V11.7323H11.7607C8.82629 11.7323 6.44057 9.38321 6.3814 6.46301C6.3222 9.38318 3.93649 11.7322 1.00214 11.7322H0.515625V11.7327Z"
                        fill="#FAC9B8"
                      />
                    </svg>
                  </UiComponentWrapper>
                </UiComponentWrapper>
              </UiComponentWrapper>
            </UiComponentWrapper>
          </UiComponentWrapper>

          <!-- Card > Calendar Integrations  -->
          <UiComponentWrapper
            class="item calendar-integrations"
            data-speed="-1.2"
          >
            <UiComponentWrapper class="inner">
              <UiComponentWrapper class="body">
                <UiComponentWrapper class="slider">
                  <UiComponentWrapper
                    v-for="({ id, illustration }, index) in data.page.showcasedFeaturesCalendarIntegrations"
                    :key="`calendar-integrations-slider-item-${id}`"
                    class="slide"
                  >
                    <UiComponentImage
                      :key="`calendar-integrations-slider-item-image-${calendarIntegrationsIllustrationSize}`"
                      :alt="illustration.alt"
                      class="illustration"
                      :class="{
                        first: index === 0,
                        last: index === data.page.showcasedFeaturesCalendarIntegrations.length - 1,
                      }"
                      :expected-size="calendarIntegrationsIllustrationSize"
                      :height="illustration.height"
                      :orientation="illustration.width >= illustration.height ? 'landscape' : 'portrait'"
                      :source="illustration.url"
                      :width="illustration.width"
                    />
                  </UiComponentWrapper>
                </UiComponentWrapper>
              </UiComponentWrapper>
              <UiComponentWrapper class="footer">
                <UiComponentHeading
                  as="paragraph"
                  :level="5"
                >
                  {{ data.page.showcasedFeaturesCalendarIntegrationsTitle }}
                </UiComponentHeading>
                <UiComponentWrapper class="menu">
                  <UiComponentWrapper
                    v-for="({ id, integration }, index) in data.page.showcasedFeaturesCalendarIntegrations"
                    :key="`calendar-integrations-menu-item-${id}`"
                    class="integration"
                    :class="[{ active: calendarIntegrationsActiveIndex === index }]"
                    @click="setCalendarIntegrationsActiveIndex(index)"
                  >
                    <UiComponentImage
                      :alt="integration.logo.alt"
                      :expected-size="24"
                      :height="integration.logo.height"
                      :source="integration.logo.url"
                      :width="integration.logo.width"
                    />
                  </UiComponentWrapper>
                </UiComponentWrapper>
              </UiComponentWrapper>
            </UiComponentWrapper>
          </UiComponentWrapper>

          <!-- Card > SAML SSO  -->
          <UiComponentWrapper
            class="item saml-sso"
            data-speed="-1.2"
            @mouseenter="onMouseEnter('saml-sso')"
            @mouseleave="onMouseLeave('saml-sso')"
          >
            <UiComponentWrapper class="inner">
              <UiComponentWrapper class="body">
                <UiComponentWrapper class="form">
                  <UiComponentWrapper class="fieldset">
                    <UiComponentParagraph class="label">
                      {{ data.page.showcasedFeaturesSamlSsoLabel }}
                    </UiComponentParagraph>
                    <UiComponentWrapper class="input">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M12.1233 6.54349C12.3879 6.34403 12.4407 5.96782 12.2412 5.7032C12.0418 5.43859 11.6656 5.38576 11.401 5.58522L8.39706 7.84945L8.39616 7.85012C8.1608 8.02822 7.83853 8.02832 7.60308 7.85042L4.6309 5.59275C4.36702 5.39231 3.99062 5.44374 3.79018 5.70761C3.58974 5.97149 3.64116 6.34789 3.90504 6.54833L6.87772 8.80637L6.8786 8.80703C7.54224 9.30922 8.45597 9.30935 9.11974 8.80743L12.1233 6.54349Z"
                          fill="#5D6D79"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M4.92373 1.86389C2.87317 1.86389 1.20117 3.51206 1.20117 5.5571V10.5062C1.20117 12.5513 2.87317 14.1994 4.92373 14.1994H11.0752C13.1257 14.1994 14.7977 12.5513 14.7977 10.5062V5.5571C14.7977 3.51206 13.1257 1.86389 11.0752 1.86389H4.92373ZM2.40117 5.5571C2.40117 4.18547 3.5252 3.06389 4.92373 3.06389H11.0752C12.4737 3.06389 13.5977 4.18547 13.5977 5.5571V10.5062C13.5977 11.8778 12.4737 12.9994 11.0752 12.9994H4.92373C3.5252 12.9994 2.40117 11.8778 2.40117 10.5062V5.5571Z"
                          fill="#5D6D79"
                        />
                      </svg>
                      <UiComponentParagraph :class="{ placeholder: samlSsoInput.isPlaceholder }">
                        {{ samlSsoInput.text }}
                      </UiComponentParagraph>
                    </UiComponentWrapper>
                  </UiComponentWrapper>
                  <UiComponentWrapper class="submit">
                    <UiComponentIcon type="lock" />
                    {{ data.page.showcasedFeaturesSamlSsoSubmitWording }}
                  </UiComponentWrapper>
                </UiComponentWrapper>
              </UiComponentWrapper>
              <UiComponentWrapper class="footer">
                <UiComponentHeading
                  as="paragraph"
                  :level="5"
                >
                  {{ data.page.showcasedFeaturesSamlSsoTitle }}
                </UiComponentHeading>
              </UiComponentWrapper>
              <UiComponentImage
                v-if="data.page.showcasedFeaturesSamlSsoIllustration"
                :key="`saml-sso-illustration-${samlSsoIllustrationSize}`"
                :alt="data.page.showcasedFeaturesSamlSsoIllustration.alt"
                class="illustration"
                :expected-size="samlSsoIllustrationSize"
                :height="data.page.showcasedFeaturesSamlSsoIllustration.height"
                orientation="portrait"
                :source="data.page.showcasedFeaturesSamlSsoIllustration.url"
                :width="data.page.showcasedFeaturesSamlSsoIllustration.width"
              />
            </UiComponentWrapper>
          </UiComponentWrapper>

          <!-- Card > Custom Branding  -->
          <UiComponentWrapper
            class="item custom-branding"
            data-speed="0.4"
            @mouseenter="onMouseEnter('custom-branding')"
            @mouseleave="onMouseLeave('custom-branding')"
          >
            <UiComponentWrapper class="inner">
              <UiComponentWrapper class="body">
                <UiComponentWrapper class="slider">
                  <UiComponentWrapper
                    v-for="({ id, background, layout }, index) in data.page.showcasedFeaturesCustomBrandingThemes"
                    :key="`custom-branding-slider-item-${id}`"
                    class="slide"
                    :class="{ active: customBrandingThemeActiveIndex === index }"
                  >
                    <UiComponentImage
                      v-if="background && customBrandingBackgroundHeight && customBrandingBackgroundWidth"
                      :key="`custom-branding-slider-item-background-${customBrandingBackgroundHeight}x${customBrandingBackgroundWidth}`"
                      :alt="background.alt"
                      class="background"
                      :expected-size="customBrandingBackgroundWidth"
                      :extra-params="{
                        fit: 'crop',
                        h: customBrandingBackgroundHeight,
                        w: customBrandingBackgroundWidth,
                      }"
                      :height="background.height"
                      :source="background.url"
                      use-natural-height
                      use-natural-width
                      :width="background.width"
                    />
                    <UiComponentImage
                      v-if="layout"
                      :key="`custom-branding-slider-item-layout-${customBrandingLayoutSize}`"
                      :alt="layout.alt"
                      class="layout"
                      :expected-size="customBrandingLayoutSize"
                      :height="layout.height"
                      :orientation="screenWidth < 1328 ? 'portrait' : 'landscape'"
                      :source="layout.url"
                      :width="layout.width"
                    />
                  </UiComponentWrapper>
                </UiComponentWrapper>
              </UiComponentWrapper>
              <UiComponentWrapper class="footer">
                <UiComponentHeading
                  as="paragraph"
                  :level="5"
                >
                  {{ data.page.showcasedFeaturesCustomBrandingTitle }}
                </UiComponentHeading>
                <UiComponentWrapper class="menu">
                  <UiComponentWrapper
                    v-for="({ id, theme }, index) in data.page.showcasedFeaturesCustomBrandingThemes"
                    :key="`custom-branding-menu-item-${id}`"
                    class="dot"
                    :class="[
                      theme.color,
                      { active: customBrandingThemeActiveIndex === index },
                    ]"
                    @click="setCustomerBrandingThemeActiveIndex(index)"
                  />
                </UiComponentWrapper>
              </UiComponentWrapper>
            </UiComponentWrapper>
          </UiComponentWrapper>

          <!-- Card > Marketing Automation & CRM Integrations  -->
          <UiComponentWrapper
            class="item marketing-automation-and-crm-integrations"
            data-speed="0.4"
            @mouseenter="onMouseEnter('marketing-automation')"
            @mouseleave="onMouseLeave('marketing-automation')"
          >
            <UiComponentWrapper class="inner">
              <UiComponentWrapper class="body">
                <UiComponentWrapper class="automations">
                  <lottie-player
                    v-if="isLottieFrameworkLoaded"
                    ref="lottie"
                    class="animation"
                    :loop="isLottieAnimationShouldLoop"
                    speed="1.5"
                    src="/lottie/homepage/data.json"
                  />
                </UiComponentWrapper>
                <UiComponentWrapper class="participants">
                  <UiComponentWrapper
                    v-for="
                      { id, profilePicture, firstName, lastName, email, country }
                        in data.page.showcasedFeaturesMarketingAutomationAndCrmIntegrationsParticipants
                    "
                    :key="`participant-${id}`"
                    class="participant"
                  >
                    <UiComponentImage
                      :alt="profilePicture.alt"
                      class="avatar"
                      :expected-size="24"
                      :extra-params="{
                        fit: 'crop',
                        h: 24,
                        w: 24,
                      }"
                      :height="profilePicture.height"
                      :source="profilePicture.url"
                      :width="profilePicture.width"
                    />
                    <UiComponentParagraph class="name">
                      {{ firstName }} {{ lastName }}
                    </UiComponentParagraph>
                    <UiComponentParagraph class="email">
                      {{ email }}
                    </UiComponentParagraph>
                    <img
                      :alt="country"
                      class="country"
                      :src="getFlagFromCountry(country)"
                    >
                  </UiComponentWrapper>
                </UiComponentWrapper>
              </UiComponentWrapper>
              <UiComponentWrapper class="footer">
                <UiComponentHeading
                  as="paragraph"
                  :level="5"
                >
                  {{ data.page.showcasedFeaturesMarketingAutomationAndCrmIntegrationsTitle }}
                </UiComponentHeading>
              </UiComponentWrapper>
            </UiComponentWrapper>
          </UiComponentWrapper>
        </UiComponentWrapper>
      </UiComponentWrapper>
    </UiComponentContainer>

    <!-- Section > All Features -->
    <UiComponentWrapper class="all-features">
      <UiComponentContainer class="inner">
        <UiComponentHeading :level="3">
          {{ data.page.allFeaturesTitle }}
        </UiComponentHeading>
      </UiComponentContainer>
      <UiComponentWrapper class="list">
        <UiComponentWrapper
          v-for="index in featuresRowCount"
          :key="`features-row-${index}`"
          class="row"
          :data-speed="featuresRowSpeed[index-1]"
        >
          <UiComponentWrapper
            v-for="{ id, shortTitle, title, theme } in getChunkFromArray(data.allFeatures, featuresRowCount, index)"
            :key="`feature-${id}`"
            class="feature"
            :class="[
              {
                highlighted: data.page.allFeaturesHighlightedFeatures
                  .map((feature) => feature.id)
                  .includes(id),
              },
              theme && theme.color,
            ]"
          >
            {{ shortTitle || title }}
          </UiComponentWrapper>
        </UiComponentWrapper>
      </UiComponentWrapper>
      <UiComponentContainer class="action">
        <AtomButton
          size="l"
          :to="localePath('features')"
          type="text"
        >
          {{ data.page.allFeaturesCtaWording }}
          <UiComponentIcon type="arrow-right" />
        </AtomButton>
      </UiComponentContainer>
    </UiComponentWrapper>

    <!-- Section > Key Numbers -->
    <UiComponentContainer
      class="key-numbers"
      data-back="#F6F7F9"
      data-enter="#12262B"
      data-start="top bottom-=200px"
      :start="1"
      :end="1"
    >
      <UiComponentHeading :level="2">
        {{ data.page.keyNumbersTitle }}
      </UiComponentHeading>
      <UiComponentParagraph>{{ data.page.keyNumbersText }}</UiComponentParagraph>
      <UiComponentWrapper class="actions">
        <AtomButton
          :action="signupOrUpgradeCallToAction.action"
          label="Key numbers section CTA"
          size="l"
          :to="signupOrUpgradeCallToAction.to"
        >
          {{ signupOrUpgradeCallToAction.slot }}
        </AtomButton>
        <AtomButton
          :action="onDemandDemoCallToAction.action"
          label="Key numbers section CTA"
          size="l"
          :to="onDemandDemoCallToAction.to"
          type="secondary"
        >
          <UiComponentIcon type="play" />
          {{ onDemandDemoCallToAction.slot }}
        </AtomButton>
      </UiComponentWrapper>
      <UiComponentWrapper class="reinsurance">
        <UiComponentWrapper class="inner">
          <!-- Card > Key Numbers -->
          <UiComponentWrapper
            v-for="({ id, illustration, text }, index) in data.page.keyNumbersItems"
            :key="`reinsurance-key-number-${id}`"
            class="key-number"
            :data-speed="[-0.6, -0.6, -0.8, -0.8][index]"
            :style="{
              '--x': keyNumbersGradientPosition[index].x,
              '--y': keyNumbersGradientPosition[index].y,
            }"
            @mousemove="onMouseMove($event, 'key-numbers', index)"
          >
            <UiComponentImage
              :alt="illustration.alt"
              class="illustration"
              :expected-size="204"
              :height="illustration.height"
              orientation="portrait"
              :source="illustration.url"
              :width="illustration.width"
            />
            <UiComponentParagraph>{{ animatedKeyNumbers[index] }}</UiComponentParagraph>
            <UiComponentParagraph>{{ text }}</UiComponentParagraph>
          </UiComponentWrapper>

          <!-- Card > Uptime -->
          <UiComponentWrapper
            v-for="{ id, uptime } in data.page.serviceLevelAgreement"
            :key="`reinsurance-sla-${id}`"
            class="uptime"
            data-speed="-0.6"
            :style="{
              '--x': uptimeGradientPosition.x,
              '--y': uptimeGradientPosition.y,
            }"
            @mousemove="onMouseMove($event, 'uptime')"
          >
            <UiComponentWrapper class="inner">
              <UiComponentParagraph>{{ animatedPercentageOfUptime.toFixed(2) }}% <span>{{ uptime }}</span></UiComponentParagraph>
              <UiComponentWrapper class="status">
                <UiComponentWrapper
                  v-for="n in 50"
                  :key="`bar-${n}`"
                  class="availability"
                />
              </UiComponentWrapper>
            </UiComponentWrapper>
          </UiComponentWrapper>

          <!-- Card > Recommended By -->
          <UiComponentWrapper
            v-for="{ id, logo, wouldRecommend } in data.page.recommendedBy"
            :key="`reinsurance-recommended-by-${id}`"
            class="recommended-by"
            data-speed="0.4"
            :style="{
              '--x': recommendedByGradientPosition.x,
              '--y': recommendedByGradientPosition.y,
            }"
            @mousemove="onMouseMove($event, 'recommended-by')"
          >
            <UiComponentImage
              v-if="logo"
              :alt="logo.alt"
              class="logo"
              :expected-size="16"
              :height="logo.height"
              :orientation="logo.width >= logo.height ? 'portrait' : 'landscape'"
              :source="logo.url"
              :width="logo.width"
            />
            <UiComponentWrapper class="data">
              <UiComponentWrapper class="graph">
                <svg
                  height="48"
                  width="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="50%"
                    cy="50%"
                    r="21"
                  />
                  <circle
                    cx="50%"
                    cy="50%"
                    r="21"
                    :style="{ strokeDashoffset: 500 - Math.round(animatedPercentageOfRecommendation / 100 * 132) }"
                  />
                </svg>
              </UiComponentWrapper>
              <UiComponentWrapper class="values">
                <UiComponentParagraph>{{ Math.round(animatedPercentageOfRecommendation) }}%</UiComponentParagraph>
                <UiComponentParagraph>{{ wouldRecommend }}</UiComponentParagraph>
              </UiComponentWrapper>
            </UiComponentWrapper>
          </UiComponentWrapper>

          <!-- Card > G2 Badges -->
          <UiComponentWrapper
            v-for="{ id, badges } in data.page.g2"
            :key="`g2-${id}`"
            class="g2"
            data-speed="-0.8"
            :style="{
              '--x': g2GradientPosition.x,
              '--y': g2GradientPosition.y,
            }"
            @mousemove="onMouseMove($event, 'g2')"
          >
            <UiComponentWrapper class="inner">
              <UiComponentWrapper
                v-for="({ id: badgeId, badge }, index) in badges"
                :key="`badge-${badgeId}`"
                class="badge"
              >
                <UiComponentImage
                  :alt="badge.alt"
                  :expected-size="badgesSize[index]"
                  :extra-params="{
                    trim: 'color',
                  }"
                  :height="badge.height"
                  :source="badge.url"
                  use-natural-height
                  use-natural-width
                  :width="badge.width"
                />
              </UiComponentWrapper>
            </UiComponentWrapper>
          </UiComponentWrapper>

          <!-- Card > Rating -->
          <UiComponentWrapper
            v-for="{ id, text } in data.page.rating"
            :key="`rating-${id}`"
            class="rating"
            data-speed="-0.8"
            :style="{
              '--x': ratingGradientPosition.x,
              '--y': ratingGradientPosition.y,
            }"
            @mousemove="onMouseMove($event, 'rating')"
          >
            <UiComponentWrapper class="stars">
              <UiComponentWrapper
                v-for="n in 5"
                :key="`star-${n}`"
                class="star"
                :style="{
                  '--rating': animatedRating - n < 0 ? 1 - Math.abs(animatedRating - n) : 1,
                }"
              />
            </UiComponentWrapper>
            <UiComponentHeading
              as="paragraph"
              :level="6"
            >
              {{ text }}
            </UiComponentHeading>
          </UiComponentWrapper>

          <!-- Card > Source -->
          <UiComponentWrapper
            class="source"
            data-speed="-0.8"
          >
            {{ data.page.keyNumbersSource }}
          </UiComponentWrapper>

          <!-- Card > Testimonials -->
          <UiComponentWrapper
            class="testimonials"
            data-speed="0.4"
          >
            <UiComponentImage
              alt="Illustation"
              class="illustration"
              :expected-size="168"
              :height="504"
              source="https://livestorm.imgix.net/1127/1704454059-quote.png"
              :width="504"
            />
            <UiComponentWrapper class="body">
              <UiComponentWrapper class="slider glide">
                <div
                  class="glide__track"
                  data-glide-el="track"
                >
                  <div class="flex glide__slides">
                    <UiComponentWrapper
                      v-for="{ id, logo, quote, author, job, profilePicture } in data.page.testimonials"
                      :key="`testimonial-${id}`"
                      class="item glide__slide"
                    >
                      <UiComponentWrapper class="company">
                        <UiComponentImage
                          v-if="logo"
                          :alt="logo.alt"
                          class="logo"
                          :expected-size="24"
                          :extra-params="{
                            con: 100,
                            gam: 100,
                            monochrome: '000000',
                            sat: -100,
                            trim: 'color',
                            vib: 100,
                          }"
                          :height="logo.height"
                          :orientation="logo.width >= logo.height ? 'portrait' : 'landscape'"
                          :source="logo.url"
                          use-natural-height
                          use-natural-width
                          :width="logo.width"
                        />

                        <UiComponentWrapper class="stars">
                          <UiComponentWrapper
                            v-for="n in 5"
                            :key="`star-${n}`"
                            class="star"
                            :style="{
                              '--rating': 4.8 - n < 0 ? 1 - Math.abs(4.8 - n) : 1,
                            }"
                          />
                        </UiComponentWrapper>
                      </UiComponentWrapper>

                      <UiComponentParagraph class="quote">
                        {{ quote }}
                      </UiComponentParagraph>

                      <UiComponentWrapper class="author">
                        <UiComponentImage
                          v-if="profilePicture"
                          :alt="profilePicture.alt"
                          class="profile"
                          :expected-size="44"
                          :extra-params="{
                            fit: 'crop',
                            h: 44,
                            w: 44,
                          }"
                          :height="profilePicture.height"
                          :source="profilePicture.url"
                          use-natural-height
                          use-natural-width
                          :width="profilePicture.width"
                        />
                        <UiComponentWrapper class="identity">
                          <UiComponentParagraph>{{ author }}</UiComponentParagraph>
                          <UiComponentParagraph>{{ job }}</UiComponentParagraph>
                        </UiComponentWrapper>
                      </UiComponentWrapper>
                    </UiComponentWrapper>
                  </div>
                </div>
              </UiComponentWrapper>
            </UiComponentWrapper>
            <UiComponentWrapper class="footer">
              <UiComponentWrapper
                v-if="data.page.testimonials.length > 1"
                class="navigation"
              >
                <UiComponentIcon
                  type="arrow-left"
                  @click="testimonialSlider.go('<')"
                />
                <UiComponentIcon
                  type="arrow-right"
                  @click="testimonialSlider.go('>')"
                />
              </UiComponentWrapper>
              <AtomButton
                size="l"
                :to="localePath('customers')"
                type="text"
              >
                {{ data.page.testimonialsCtaWording }}
                <UiComponentIcon type="arrow-right" />
              </AtomButton>
            </UiComponentWrapper>
          </UiComponentWrapper>
        </UiComponentWrapper>
      </UiComponentWrapper>
    </UiComponentContainer>

    <!-- Section > See It in Action -->
    <UiComponentContainer
      class="see-it-in-action"
      :start="1"
      :end="1"
    >
      <UiComponentWrapper class="background">
        <UiComponentImage
          v-if="data.page.seeItInActionBackground"
          :key="`see-it-action-background-${seeItInActionBackgroundSize}`"
          :alt="data.page.seeItInActionBackground.alt"
          :expected-size="seeItInActionBackgroundSize"
          :extra-params="seeItInActionBackgroundParams"
          :height="data.page.seeItInActionBackground.height"
          :source="data.page.seeItInActionBackground.url"
          use-natural-height
          use-natural-width
          :width="data.page.seeItInActionBackground.width"
        />
      </UiComponentWrapper>
      <UiComponentWrapper class="box">
        <UiComponentHeading :level="2">
          {{ data.page.seeItInActionTitle }}
        </UiComponentHeading>
        <UiComponentParagraph>{{ data.page.seeItInActionText }}</UiComponentParagraph>
        <UiComponentWrapper class="actions">
          <AtomButton
            :action="onDemandDemoCallToAction.action"
            label="Attend demo section CTA"
            size="l"
            :to="onDemandDemoCallToAction.to"
          >
            <UiComponentIcon
              theme="dark"
              type="play"
            />
            {{ onDemandDemoCallToAction.slot }}
          </AtomButton>
        </UiComponentWrapper>
      </UiComponentWrapper>
    </UiComponentContainer>

    <!-- Section > Create an Account -->
    <OrganismCreateAnAccount
      data-back="#12262B"
      data-enter="#12262B"
      data-start="top top"
      data-end="bottom center"
      :component-global="data.componentGlobal"
      :request-demo-call-to-action="requestDemoCallToAction"
      :signup-or-upgrade-call-to-action="signupOrUpgradeCallToAction"
      :text="data.componentCreateAFreeAccount.text"
      :title="data.componentCreateAFreeAccount.title"
    />

    <!-- Section > Latest Articles & Guides -->
    <UiComponentWrapper
      v-if="data.allBlogPosts.length > 0"
      class="latest-articles-and-guides"
    >
      <UiComponentContainer class="inner">
        <UiComponentWrapper
          v-if="data.allBlogPosts.length > 0"
          class="latest-articles"
          :class="{ full: data.page.ourGuidesItems.length === 0 }"
        >
          <UiComponentHeading :level="2">
            {{ data.page.livestormBlogTitle }}
          </UiComponentHeading>
          <UiComponentWrapper class="articles">
            <UiComponentWrapper
              v-for="{ id, category, slug, title } in data.allBlogPosts"
              :key="id"
              class="article"
              @click="
                push(
                  localePath({
                    name: 'blog-slug',
                    params: {
                      slug,
                    },
                  }),
                )
              "
            >
              <UiComponentWrapper class="category">
                {{
                  category[0].name
                }}
              </UiComponentWrapper>
              <NuxtLink
                class="title"
                :prefetch="false"
                :to="
                  localePath({
                    name: 'blog-slug',
                    params: {
                      slug,
                    },
                  })
                "
              >
                {{ title }}
              </NuxtLink>
              <AtomButton type="text">
                {{ data.componentBlog.readMore }}
                <UiComponentIcon type="arrow-right" />
              </AtomButton>
            </UiComponentWrapper>
          </UiComponentWrapper>
        </UiComponentWrapper>
        <UiComponentWrapper
          v-if="data.page.ourGuidesItems.length > 0"
          class="guides"
        >
          <UiComponentHeading :level="2">
            {{ data.page.ourGuidesTitle }}
          </UiComponentHeading>
          <UiComponentWrapper class="items">
            <AtomButton
              v-for="{ id, slot, to } in data.page.ourGuidesItems"
              :key="id"
              class="item"
              :to="to"
              type="text"
            >
              {{ slot }}
              <UiComponentIcon type="arrow-right" />
            </AtomButton>
          </UiComponentWrapper>
        </UiComponentWrapper>
      </UiComponentContainer>
    </UiComponentWrapper>
  </UiComponentWrapper>
</template>

<script setup>
// Imports
import Glide from '@glidejs/glide'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

// Layout
definePageMeta({
  layout: 'default',
})

// i18n
defineI18nRoute({
  paths: {
    en: '/',
    es: '/',
    fr: '/',
  },
})

// Composables
const { page, track } = useAnalyticsJS()
const { setAvailableLanguages } = useAvailableLanguages()
const { updateListItems } = useBreadcrumb()
const { state } = useConditionalPreviewMode()
const { getCountryCode } = useCountryList()
const { isDesktop, isMobileOrTablet } = useDevice()
const { locale } = useI18n()
const { isLogged } = useIsLoggedIn()
const localePath = useLocalePath()
const { $lottiePlayer } = useNuxtApp()
const { push } = useRouter()
const { public: publicRuntimeConfig } = useRuntimeConfig()
const { dpr, width: screenWidth } = useScreenSize()

// Async Data
const { data } = await useFetch(`/api/content/pages/homepage/${locale.value}`, {
  key: `page-homepage-${locale.value}`,
  query: {
    preview: state.preview,
  },
})

if (!data.value) {
  reloadNuxtApp()
}

// Meta
const head = useLocaleHead({
  identifierAttribute: 'id',
  addSeoAttributes: true,
})

useSeoMeta({
  title: data.value.page.seoTitle,
  description: data.value.page.seoDescription,
  ogTitle: data.value.page.seoTitle,
  ogDescription: data.value.page.seoDescription,
  twitterTitle: data.value.page.seoTitle,
  twitterDescription: data.value.page.seoDescription,
})

useHead({
  link: head.value.link,
  meta: head.value.meta,
  script: [
    {
      innerHTML: JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        'itemListElement': [
          {
            '@type': 'ListItem',
            'position': 1,
            'name': data.value.page.trackingBreadcrumbTitle,
          },
        ],
      }),
      type: 'application/ld+json',
    },
  ],
})

// Refs
const animatedKeyNumbers = ref(['', '', '', ''])
const animatedPercentageOfRecommendation = ref(0)
const animatedPercentageOfUptime = ref(0)
const animatedRating = ref(0)
const badgesSize = ref([136, 120, 120, 64, 80])
const breakpoints = ref({
  sm: '(max-width: 767px)',
  md: '(min-width: 768px) and (max-width: 1327px)',
  xl: '(min-width: 1328px)',
})
const calendarIntegrationsActiveIndex = ref(0)
const calendarIntegrationsIsAnimated = ref(false)
const currentBackgroundColor = ref('#FFFFFF')
const customBrandingBackgroundHeight = ref(null)
const customBrandingBackgroundWidth = ref(null)
const customBrandingResizeObserver = ref(null)
const customBrandingThemeActiveIndex = ref(3)
const customBrandingThemeIsAnimated = ref(false)
const featuresRowCount = ref(3)
const featuresRowSpeed = ref([0.4, -0.1, 0.2])
const g2GradientPosition = ref({
  x: '50%',
  y: '50%',
})
const getStartedSectionMask = ref({ r: 60, x: 50, y: 50 })
const industriesActiveIndex = ref(0)
const isLottieAnimationLoaded = ref(false)
const isLottieAnimationShouldLoop = ref(false)
const isLottieFrameworkLoaded = ref(false)
const keyNumbersGradientPosition = ref(
  Array.from({ length: 4 }).map(() => ({
    x: '50%',
    y: '50%',
  })),
)
const ratingGradientPosition = ref({
  x: '50%',
  y: '50%',
})
const recommendedByGradientPosition = ref({
  x: '50%',
  y: '50%',
})
const samlSsoInput = ref({
  isPlaceholder: true,
  text: data.value.page.showcasedFeaturesSamlSsoPlaceholder,
})
const testimonialSlider = ref(null)
const useCasesActiveIndex = ref(1)
const uptimeGradientPosition = ref({
  x: '50%',
  y: '50%',
})
const videoIsDisplayed = ref(false)

// Variables
let allFeaturesTimeline = null
let customBrandingTimeline = null
let equalizerTimeline = null
let keyNumbersTimeline = null
let matchMedia = null
let marketingAutomationTimeline = null
let modalTimeline = null
let samlSsoTimeline = null

// Computed Properties
const calendarIntegrationsIllustrationSize = computed(() => {
  return screenWidth.value < 1328 ? 210 : 320
})

const containerOffset = computed(() => {
  switch (true) {
    case screenWidth.value < 768:
      return 16
    case screenWidth.value >= 768 && screenWidth.value < 1328:
      return 24
    default:
      return (screenWidth.value - 1280) / 2
  }
})

const customBrandingLayoutSize = computed(() => {
  switch (true) {
    case screenWidth.value < 768:
      return customBrandingBackgroundHeight.value - 98
    case screenWidth.value >= 768 && screenWidth.value < 1328:
      return customBrandingBackgroundHeight.value - 140
    default:
      return 336
  }
})

const customers = computed(() => {
  return data.value.page.heroHighlightedCustomers
    .filter(({ logo }) => !!logo)
})

const industryImageSize = computed(() => {
  switch (true) {
    case screenWidth.value < 768:
      return screenWidth.value - 80
    case screenWidth.value >= 768 && screenWidth.value < 1328:
      return Math.min(Math.max(screenWidth.value - 144, 1), 624)
    default:
      return 620
  }
})

const industriesSectionSize = computed(() => {
  switch (true) {
    case screenWidth.value < 768:
      return screenWidth.value - 32
    case screenWidth.value >= 768 && screenWidth.value < 1328:
      return screenWidth.value - 48
    default:
      return 1280
  }
})

const onDemandDemoCallToAction = computed(() => {
  return {
    action: 'On-demand demo clicked',
    slot: data.value.componentGlobal.ctaOnDemandDemo,
    to: data.value.componentDemo.link,
  }
})

const questionsTabAvatarSize = computed(() => {
  return screenWidth.value < 1328 ? 13.303 : 17.383
})

const questionsTabAnswerAvatarSize = computed(() => {
  return screenWidth.value < 1328 ? 9.977 : 13.037
})

const registrationAndCompanyPageImageSize = computed(() => {
  return screenWidth.value < 1328 ? 347 : 404
})

const requestDemoCallToAction = computed(() => {
  return {
    action: 'Demo clicked',
    slot: data.value.componentGlobal.ctaGetDemo,
    to: '#form-demo',
  }
})

const samlSsoIllustrationSize = computed(() => {
  return screenWidth.value < 1328 ? 176 : 212
})

const seeItInActionBackgroundParams = computed(() => {
  switch (true) {
    case screenWidth.value >= 768 && screenWidth.value < 1328:
      return {
        fit: 'crop',
        h: 396,
        w: seeItInActionBackgroundSize.value,
      }
    default:
      return undefined
  }
})

const seeItInActionBackgroundSize = computed(() => {
  return screenWidth.value < 1328 ? Math.min(Math.max(screenWidth.value - 48, 1), 1060) : 1060
})

const signupOrUpgradeCallToAction = computed(() => {
  return isLogged.value
    ? {
        action: 'Upgrade clicked',
        to: `https://${publicRuntimeConfig.appBaseUrl}/#/settings?page=settings&tab=billing`,
        slot: data.value.componentGlobal.ctaUpgradeNow,
      }
    : {
        action: 'Signup clicked',
        to: `https://${publicRuntimeConfig.appBaseUrl}/#/signup`,
        slot: data.value.componentGlobal.ctaGetStartedForFree,
      }
})

const useCaseImageParams = computed(() => {
  return screenWidth.value < 1328
    ? null
    : {
        fit: 'crop',
        h: 435,
        w: useCaseImageSize.value,
      }
})

const useCaseImageSize = computed(() => {
  switch (true) {
    case screenWidth.value < 768:
      return screenWidth.value - 32
    case screenWidth.value >= 768 && screenWidth.value < 1328:
      return screenWidth.value - 48
    default:
      return 894
  }
})

// Watchers
watch(
  () => videoIsDisplayed.value,
  (value) => {
    if (value) {
      document.body.classList.add('no-scroll')
    }
    else {
      document.body.classList.remove('no-scroll')
    }
  },
)

// Methods
function animateBackgroundColor() {
  const page = document.body.querySelector('.page');
  [...page.children]
    .filter(({ dataset }) => {
      return 'back' in dataset === true
        || 'enter' in dataset === true
        || 'start' in dataset === true
        || 'end' in dataset === true
    })
    .forEach((trigger) => {
      const { classList, dataset } = trigger
      const { start, end } = trigger
      const duration = 0.6
      const config = {
        trigger,
        start,
        end,
      }

      if (trigger.dataset.back) {
        Object.assign(config, {
          onLeaveBack: () => gsap.to('body', {
            backgroundColor: trigger.dataset.back,
            duration,
            onStart: () => {
              if (classList.contains('key-numbers')) {
                allFeaturesTimeline.reverse()
                keyNumbersTimeline.seek(0)
                keyNumbersTimeline.pause()
              }
            },
          }),
        })
      }

      if (trigger.dataset.enter) {
        const props = {
          backgroundColor: trigger.dataset.enter,
          duration,
          onStart: () => {
            if (classList.contains('key-numbers')) {
              allFeaturesTimeline.play()
              keyNumbersTimeline.play()
            }
          },
        }
        Object.assign(config, {
          onEnter: () => gsap.to('body', props),
          onEnterBack: () => gsap.to('body', props),
        })
      }

      ScrollTrigger.create(config)
    })
}

function animateHeroSection({ conditions }) {
  const { xl } = conditions

  gsap.to(
    '.page .hero .assets .video',
    {
      ease: 'none',
      height: () => {
        switch (true) {
          case xl:
            return 440
          default:
            return undefined
        }
      },
      onStart: () => {
        document.querySelector('.page .hero .assets .video video').play()
      },
      scrollTrigger: {
        trigger: '.page .hero .assets',
        start: () => {
          const { offsetTop } = document.querySelector('.page .hero .assets .video').parentNode
          return `-=${offsetTop}px center`
        },
        end: '220px center',
        scrub: true,
      },
      width: () => {
        switch (true) {
          case xl:
            return 620
          default:
            return undefined
        }
      },
    },
  )
}

function animateIndustriesSection({ conditions }) {
  const { xl } = conditions

  gsap.from(
    '.page .industries',
    {
      autoAlpha: () => xl ? 0 : undefined,
      borderRadius: 0,
      ease: 'none',
      rotationX: -10,
      scale: () => xl ? 0.95 : undefined,
      scrollTrigger: {
        trigger: '.page .industries',
        start: 'top bottom',
        end: 'center bottom',
        scrub: true,
      },
      y: () => xl ? 40 : undefined,
    },
  )
}

function animateUseCasesSection({ conditions }) {
  const { xl } = conditions

  gsap.from(
    [
      '.page .use-cases > .h2',
      '.page .use-cases > .h2 + p',
      '.page .use-cases > .actions',
      '.page .use-cases > .menu',
      '.page .use-cases > .action',
    ],
    {
      autoAlpha: () => xl ? 0 : undefined,
      ease: 'none',
      scrollTrigger: {
        trigger: '.page .use-cases',
        end: 'center center',
        scrub: true,
      },
      stagger: () => xl ? 0.1 : undefined,
      y: index => xl ? Math.pow(2, index) * 10 : undefined,
    },
  )
}

function animateShowcasedFeaturesSection({ conditions }) {
  createCustomBrandingTimeline()
  createEqualizerTimeline()
  createMarketingAutomationTimeline()
  createSamlSsoTimeline()

  const { xl } = conditions

  // Hero
  gsap.from(
    [
      '.page .showcased-features .h2',
      '.page .showcased-features .h2 + p',
      '.page .showcased-features .actions',
    ],
    {
      autoAlpha: () => xl ? 0 : undefined,
      ease: 'none',
      scrollTrigger: {
        trigger: '.page .showcased-features .h2',
        scrub: true,
      },
      stagger: () => xl ? 0.1 : undefined,
      y: index => xl ? Math.pow(2, index) * 4 : undefined,
    },
  )

  gsap.from(
    '.page .showcased-features .list .outer .item',
    {
      ease: 'none',
      scrollTrigger: {
        trigger: '.page .showcased-features',
        start: 'center bottom',
        end: 'center top',
        scrub: true,
      },
      y: (_, $el) => {
        if (xl) {
          const speed = Number.parseFloat($el.dataset.speed)
          return 80 * speed
        }

        return undefined
      },
    },
  )
}

function animateAllFeaturesSection() {
  if (allFeaturesTimeline) {
    allFeaturesTimeline.kill()
    allFeaturesTimeline = null
  }

  allFeaturesTimeline = gsap.timeline()
  allFeaturesTimeline.to(
    [
      '.page .all-features .h3',
      '.page .all-features .action .button',
      '.page .all-features .action .button .icon',
    ],
    {
      color: '#FFFFFF',
      duration: 0.6,
    },
  )
  allFeaturesTimeline.pause()

  gsap.to('.page .all-features .row', {
    ease: 'none',
    scrollTrigger: {
      trigger: '.page .all-features',
      start: 'center bottom',
      end: 'center top',
      scrub: true,
    },
    x: (_, $row) => {
      const speed = Number.parseFloat($row.dataset.speed)
      return 200 * speed
    },
  })
}

function animateKeyNumbersSection({ conditions }) {
  const { xl } = conditions

  // Scroll Trigger
  gsap.from(
    [
      '.page .key-numbers .reinsurance .key-number',
      '.page .key-numbers .reinsurance .g2',
      '.page .key-numbers .reinsurance .recommended-by',
      '.page .key-numbers .reinsurance .rating',
      '.page .key-numbers .reinsurance .uptime',
      '.page .key-numbers .reinsurance .testimonials',
      '.page .key-numbers .reinsurance .source',
    ],
    {
      ease: 'none',
      scrollTrigger: {
        trigger: '.page .key-numbers .reinsurance',
        scrub: true,
      },
      y: (_, $el) => {
        if (xl) {
          const speed = Number.parseFloat($el.dataset.speed)
          return 80 * speed
        }

        return undefined
      },
    },
    0,
  )

  // Timeline
  if (keyNumbersTimeline) {
    keyNumbersTimeline.kill()
    keyNumbersTimeline = null
  }

  keyNumbersTimeline = gsap.timeline()

  // Hero
  keyNumbersTimeline.from(
    [
      '.page .key-numbers .h2',
      '.page .key-numbers .h2 + p',
      '.page .key-numbers .actions',
    ],
    {
      autoAlpha: 0,
      duration: 1,
      stagger: 0.1,
      y: index => Math.pow(2, index) * 10,
    },
    0,
  )

  // G2 Badges
  gsap.utils
    .toArray('.page .key-numbers .reinsurance .g2 .badge')
    .forEach(($badge, index) => {
      if (index > 0) {
        keyNumbersTimeline.from(
          $badge,
          {
            duration: 1,
            left: '50%',
            top: '50%',
          },
          0,
        )
      }
    })

  // Key Numbers
  gsap.utils
    .toArray('.page .key-numbers .reinsurance .key-number')
    .forEach((trigger, index) => {
      const { title } = data.value.page.keyNumbersItems[index]
      const [value] = data.value.page.keyNumbersItems[index].title.match(/\d+/g).map(Number)
      const unit = title.replace(value, '')
      const unitIsAfter = title.indexOf(value) === 0
      const proxy = {
        value,
      }

      keyNumbersTimeline.from(
        proxy,
        {
          duration: 1,
          onUpdate: () => {
            animatedKeyNumbers.value[index] = unitIsAfter
              ? `${Math.round(proxy.value)}${unit}`
              : `${unit}${Math.round(proxy.value)}`
          },
          value: 0,
        },
        0,
      )
    })

  // Recommended By
  keyNumbersTimeline.to(
    animatedPercentageOfRecommendation,
    {
      duration: 1,
      value: data.value.page.recommendedBy[0].percentage,
    },
    0,
  )

  // Rating
  keyNumbersTimeline.to(
    animatedRating,
    {
      duration: 1,
      value: data.value.page.rating[0].averageScore,
    },
    0,
  )

  // Uptime
  keyNumbersTimeline.to(
    animatedPercentageOfUptime,
    {
      duration: 1,
      value: data.value.page.serviceLevelAgreement[0].percentage,
    },
    0,
  )
  keyNumbersTimeline.from(
    '.page .key-numbers .reinsurance .uptime .status .availability',
    {
      duration: 1,
      height: 0,
      stagger: 0.01,
    },
    0,
  )
  keyNumbersTimeline.timeScale(1.4)
  keyNumbersTimeline.pause()
}

function animateSeeItInActionSection({ conditions }) {
  const { xl } = conditions

  gsap.from(
    '.page .see-it-in-action',
    {
      scrollTrigger: {
        trigger: '.page .see-it-in-action',
        end: 'bottom 60%',
        scrub: true,
      },
      autoAlpha: () => xl ? 0 : undefined,
    },
  )
}

function animateGetStartedSection({ conditions }) {
  const { xl } = conditions

  gsap.from(
    [
      '.page .create-an-account .overlay .logo',
      '.page .create-an-account .overlay .h2',
      '.page .create-an-account .overlay .h2 + p',
      '.page .create-an-account .overlay .actions',
      '.page .create-an-account .overlay .features',
    ],
    {
      autoAlpha: () => xl ? 0 : undefined,
      ease: 'none',
      scrollTrigger: {
        end: 'bottom 90%',
        trigger: '.page .create-an-account',
        scrub: true,
      },
      stagger: () => xl ? 0.1 : undefined,
      y: index => xl ? Math.pow(2, index) * 10 : undefined,
    },
  )
}

function closeModal() {
  videoIsDisplayed.value = false
}

function createCustomBrandingTimeline() {
  if (isMobileOrTablet) {
    return
  }

  if (customBrandingTimeline) {
    customBrandingTimeline.kill()
    customBrandingTimeline = null
  }

  customBrandingTimeline = gsap.timeline()
  customBrandingTimeline.from(
    '.page .showcased-features .list .custom-branding .inner .footer .menu .dot',
    {
      autoAlpha: 0,
      y: 16,
      stagger: 0.1,
      duration: 0.6,
      ease: 'expo.out',
    },
  )
  customBrandingTimeline.pause()
}

function createEqualizerTimeline() {
  if (equalizerTimeline) {
    equalizerTimeline.kill()
    equalizerTimeline = null
  }

  equalizerTimeline = gsap.timeline({
    repeat: -1,
    yoyo: true,
  })
  equalizerTimeline.addLabel('zero', 0)

  const barCount = 10
  const lineCount = 5
  let count = 0
  let startAt = Array.from(
    { length: barCount },
    () => Math.max(Math.floor(Math.random() * lineCount), 2),
  )

  for (let i = 0; i <= barCount; i++) {
    count = 0
    for (let n = startAt[i]; n <= lineCount; n++) {
      equalizerTimeline.fromTo(
        `.page .showcased-features .list .ai-transcription .equalizer .bar-${i + 1} .line-${n}`,
        {
          autoAlpha: 0,
        },
        {
          autoAlpha: 1,
          duration: 0.4,
          ease: 'sine.in',
          yoyo: true,
        },
        `zero+=${count * 0.2}`,
      )
      count++
    }
  }

  const labels = ['one', 'two']

  labels.forEach((label, index) => {
    equalizerTimeline.addLabel(label, (index + 1) * 2)

    startAt = Array.from(
      { length: barCount },
      () => Math.floor(Math.random() * lineCount),
    )

    for (let i = 0; i <= barCount; i++) {
      count = 0
      for (let j = lineCount; j > startAt[i]; j--) {
        equalizerTimeline.to(
          `.page .showcased-features .list .ai-transcription .equalizer .bar-${i + 1} .line-${j}`,
          {
            autoAlpha: 0,
            duration: 0.4,
            ease: 'sine.in',
            yoyo: true,
          },
          `${label}+=${count * 0.2}`,
        )
        count++
      }

      for (let k = startAt[i]; k <= lineCount; k++) {
        equalizerTimeline.to(
          `.page .showcased-features .list .ai-transcription .equalizer .bar-${i + 1} .line-${k + 1}`,
          {
            autoAlpha: 1,
            duration: 0.4,
            ease: 'sine.in',
            yoyo: true,
          },
          `${label}+=${count * 0.2}`,
        )
        count++
      }
    }
  })

  equalizerTimeline.timeScale(6)
  equalizerTimeline.pause()
}

function createMarketingAutomationTimeline() {
  if (isMobileOrTablet) {
    return
  }

  if (marketingAutomationTimeline) {
    marketingAutomationTimeline.kill()
    marketingAutomationTimeline = null
  }

  marketingAutomationTimeline = gsap.timeline()
  marketingAutomationTimeline.from(
    '.page .showcased-features .list .marketing-automation-and-crm-integrations .participants',
    {
      y: 16,
      duration: 0.8,
      ease: 'expo.out',
    },
    0,
  )
  marketingAutomationTimeline.to(
    '.page .showcased-features .list .marketing-automation-and-crm-integrations .participants',
    {
      '--via': '#62AABC',
      'duration': 1,
      'ease': 'expo.inOut',
    },
    0,
  )
  marketingAutomationTimeline.pause()
}

function createSamlSsoTimeline() {
  if (isMobileOrTablet) {
    return
  }

  if (samlSsoTimeline) {
    samlSsoTimeline.kill()
    samlSsoTimeline = null
  }

  samlSsoTimeline = gsap.timeline()
  samlSsoTimeline.fromTo(
    '.page .showcased-features .list .saml-sso .form',
    {
      height: 72,
    },
    {
      height: 136,
      duration: 1,
      ease: 'expo.inOut',
    },
    0,
  )
  samlSsoTimeline.from(
    '.page .showcased-features .list .saml-sso .form .submit',
    {
      autoAlpha: 0,
      duration: 0.8,
      ease: 'expo.inOut',
    },
    0,
  )
  samlSsoTimeline.set(
    samlSsoInput.value,
    {
      isPlaceholder: false,
      text: '',
    },
    0.6,
  )
  samlSsoTimeline.to(
    {},
    {
      duration: 0.4,
      ease: 'expo.out',
      onUpdate: function () {
        const value = 'virginia@acme.co'
        const end = Math.round((this.time() / 0.4) * value.length)
        samlSsoInput.value.text = value.substring(0, end)
      },
    },
    0.61,
  )
  samlSsoTimeline.pause()
}

function getChunkFromArray(array, divider, index) {
  const length = Math.ceil(array.length / divider)
  const chunk = [...array]
    .slice(length * (index - 1), length * index)
  return chunk
}

function getDescriptionFromKeyNumber(value) {
  const [_, ...occurrences] = value.split(' ')
  const text = occurrences.join(' ')
  return text
}

function getFlagFromCountry(value) {
  const countryCode = getCountryCode(value)
  return `https://flagcdn.com/${countryCode.toLowerCase()}.svg`
}

function getTimeInHumanReadableFormat(seconds) {
  return (seconds - (seconds %= 60)) / 60 + (9 < seconds ? ':' : ':0') + seconds
}

function getTitleFromKeyNumber(value) {
  const [title] = value.split(' ')
  return title
}

function onEnter($el, done) {
  if (modalTimeline) {
    modalTimeline.kill()
    modalTimeline = null
  }

  modalTimeline = gsap.timeline()
  modalTimeline.eventCallback('onComplete', () => {
    done()
  })
  modalTimeline.from(
    $el.querySelector('.background'),
    {
      autoAlpha: 0,
      duration: 0.8,
      ease: 'expo.out',
    },
    0,
  )
  modalTimeline.from(
    $el.querySelector('.box'),
    {
      autoAlpha: 0,
      scale: 0.9,
      duration: 0.8,
      ease: 'expo.out',
    },
    0.1,
  )
  modalTimeline.play()
}

function onLeave($el, done) {
  modalTimeline.eventCallback('onReverseComplete', () => {
    done()
  })
  modalTimeline.timeScale(1.5)
  modalTimeline.reverse()
}

function onMouseEnter(item) {
  switch (item) {
    case 'ai-transcription':
      if (isDesktop) {
        equalizerTimeline.play()
      }
      break
    case 'custom-branding':
      customBrandingTimeline.play()
      break
    case 'marketing-automation':
      marketingAutomationTimeline.play()
      if (isLottieAnimationLoaded.value && isDesktop) {
        isLottieAnimationShouldLoop.value = true
        const player = document.querySelector('.page lottie-player')
        player.seek(0)
        player.play()
      }
      break
    case 'saml-sso':
      samlSsoTimeline.play()
      break
    default:
  }
}

function onMouseLeave(item) {
  switch (item) {
    case 'ai-transcription':
      equalizerTimeline.progress(0)
      equalizerTimeline.pause()
      break
    case 'custom-branding':
      customBrandingTimeline.reverse()
      break
    case 'marketing-automation':
      marketingAutomationTimeline.reverse()
      if (isLottieAnimationLoaded.value && isDesktop) {
        isLottieAnimationShouldLoop.value = false
      }
      break
    case 'saml-sso':
      samlSsoTimeline.reverse()
      break
    default:
  }
}

function onMouseMove({ currentTarget, layerX, layerY, x, y }, item, index) {
  switch (item) {
    case 'g2':
      g2GradientPosition.value = {
        x: `${(layerX / currentTarget.offsetWidth) * 100}%`,
        y: `${(layerY / currentTarget.offsetHeight) * 100}%`,
      }
      break
    case 'key-numbers':
      keyNumbersGradientPosition.value[index] = {
        x: `${(layerX / currentTarget.offsetWidth) * 100}%`,
        y: `${(layerY / currentTarget.offsetHeight) * 100}%`,
      }
      break
    case 'rating':
      ratingGradientPosition.value = {
        x: `${(layerX / currentTarget.offsetWidth) * 100}%`,
        y: `${(layerY / currentTarget.offsetHeight) * 100}%`,
      }
      break
    case 'recommended-by':
      recommendedByGradientPosition.value = {
        x: `${(layerX / currentTarget.offsetWidth) * 100}%`,
        y: `${(layerY / currentTarget.offsetHeight) * 100}%`,
      }
      break
    case 'showcased-features':
      const percentage = ((x - containerOffset.value) / currentTarget.offsetWidth) * 100
      gsap.set('.page .showcased-features .registration-and-company-page .branded', {
        width: `${percentage}%`,
      })
      break
    case 'uptime':
      uptimeGradientPosition.value = {
        x: `${(layerX / currentTarget.offsetWidth) * 100}%`,
        y: `${(layerY / currentTarget.offsetHeight) * 100}%`,
      }
      break
    default:
  }
}

function onObserve() {
  const { offsetHeight, offsetWidth } = document.querySelector('.page .showcased-features .list .custom-branding')
  customBrandingBackgroundHeight.value = offsetHeight
  customBrandingBackgroundWidth.value = offsetWidth
}

function onReady() {
  isLottieAnimationLoaded.value = true

  if (isMobileOrTablet) {
    const player = document.querySelector('.page lottie-player')
    player.seek('100%')
    player.play()
  }
}

function onResize() {
  if (isMobileOrTablet) {
    return
  }
  ScrollTrigger.refresh()
}

function openModal() {
  track('Video clicked', {
    category: 'Button',
    label: 'Watch the video CTA',
  })
  videoIsDisplayed.value = true
}

function setCalendarIntegrationsActiveIndex(value) {
  if (calendarIntegrationsActiveIndex.value === value || calendarIntegrationsIsAnimated.value) {
    return
  }

  const xPercent = -(100 * value)

  calendarIntegrationsActiveIndex.value = value
  calendarIntegrationsIsAnimated.value = true

  gsap.to(
    '.page .showcased-features .list .calendar-integrations .slider',
    {
      xPercent,
      duration: 0.8,
      ease: 'expo.inOut',
      onComplete: () => {
        calendarIntegrationsIsAnimated.value = false
      },
    },
  )
}

function setCustomerBrandingThemeActiveIndex(value) {
  if (customBrandingThemeActiveIndex.value === value || customBrandingThemeIsAnimated.value) {
    return
  }

  const $slides = gsap.utils.toArray('.page .showcased-features .list .custom-branding .slide')
  const { value: oldValue } = customBrandingThemeActiveIndex

  customBrandingThemeActiveIndex.value = value
  customBrandingThemeIsAnimated.value = true

  gsap.to($slides[value], {
    autoAlpha: 1,
    duration: 0.8,
    onStart: () => {
      gsap.set($slides, {
        zIndex: 0,
      })
      gsap.set($slides[value], {
        zIndex: 10,
      })
    },
    onComplete: () => {
      gsap.set($slides[oldValue], {
        autoAlpha: 0,
      })
      customBrandingThemeIsAnimated.value = false
    },
  })
}

function setIndustriesActiveIndex(value) {
  if (industriesActiveIndex.value === value) {
    return
  }

  industriesActiveIndex.value = value

  track('Tag clicked', {
    category: 'Button',
    label: `Tag ${data.value.page.industriesHighlightedItems[value].industry.trackName}`,
  })
}

function setUseCasesActiveIndex(value) {
  if (useCasesActiveIndex.value === value) {
    return
  }

  useCasesActiveIndex.value = value
}

// Lifecycle Hooks
onBeforeUnmount(() => {
  if (allFeaturesTimeline) {
    allFeaturesTimeline.kill()
    allFeaturesTimeline = null
  }

  customBrandingResizeObserver.value?.disconnect()

  if (customBrandingTimeline) {
    customBrandingTimeline.kill()
    customBrandingTimeline = null
  }

  document.body.classList.remove('no-scroll')

  if (equalizerTimeline) {
    equalizerTimeline.kill()
    equalizerTimeline = null
  }

  gsap.set(
    'body',
    {
      backgroundColor: '#FFFFFF',
    },
  )

  gsap.killTweensOf('.page *')

  if (keyNumbersTimeline) {
    keyNumbersTimeline.kill()
    keyNumbersTimeline = null
  }

  if (marketingAutomationTimeline) {
    marketingAutomationTimeline.kill()
    marketingAutomationTimeline = null
  }

  if (modalTimeline) {
    modalTimeline.kill()
    modalTimeline = null
  }

  if (samlSsoTimeline) {
    samlSsoTimeline.kill()
    samlSsoTimeline = null
  }

  ScrollTrigger.killAll()

  if (data.value.page.testimonials.length > 1) {
    testimonialSlider.value.destroy()
  }

  window.removeEventListener('resize', onResize)
})

onMounted(() => {
  $lottiePlayer.init(() => {
    isLottieFrameworkLoaded.value = true

    nextTick(() => {
      document.querySelector('lottie-player').addEventListener('ready', onReady)
    })
  })
  customBrandingResizeObserver.value = new ResizeObserver(onObserve)
  customBrandingResizeObserver.value.observe(
    document.querySelector('.page .showcased-features .list .custom-branding'),
  )
  matchMedia = gsap.matchMedia()
  matchMedia.add(
    breakpoints.value,
    (context) => {
      animateBackgroundColor()
      animateHeroSection(context)
      animateIndustriesSection(context)
      animateUseCasesSection(context)
      animateShowcasedFeaturesSection(context)
      animateAllFeaturesSection(context)
      animateKeyNumbersSection(context)
      animateSeeItInActionSection(context)
      animateGetStartedSection(context)
    },
  )
  onResize()
  page(data.value.page.trackName || 'Home')
  setAvailableLanguages()

  if (data.value.page.testimonials.length > 1) {
    testimonialSlider.value = new Glide('.page .key-numbers .testimonials .slider', {
      gap: 0,
      perView: 1,
      type: 'slider',
    })
    testimonialSlider.value.mount()
  }

  updateListItems([
    {
      item: localePath('index'),
      name: data.value.page.trackingBreadcrumbTitle,
    },
  ])
  window.addEventListener('resize', onResize)
})
</script>

<style lang="postcss" scoped>
@import "../assets/css/glide.css";

.page {
  @apply flex-col items-center overflow-hidden;

  .modal {
    @apply items-center;

    :deep(.box) {
      @apply relative w-full p-0;
      max-width: calc(100% - 32px);

      @screen md {
        @apply max-w-3xl p-0;
      }

      @screen xl {
        @apply max-w-large;
      }

      img {
        filter: brightness(0) saturate(100%) invert(100%) sepia(20%) saturate(28%) hue-rotate(53deg) brightness(106%) contrast(107%);
      }
    }
  }

  .hero {
    @apply flex-col items-center mt-4;

    @screen md {
      @apply mt-8;
    }

    .h1 {
      @apply font-bold text-center text-4xl leading-12;

      @screen md {
        @apply text-6xl leading-16;
      }

      :deep(strong) {
        @apply relative font-bold;

        &::after {
          @apply absolute bottom-[2%] left-[6%] w-full bg-livestorm-blue-100 content-[''] transform -z-10;
          height: calc((37 / 64) * 100%);
        }
      }

      & + p {
        @apply mt-4 text-center text-grey-blue-600;
      }
    }

    .actions {
      @apply flex-col mt-8;

      @screen md {
        @apply flex-row w-auto;
      }

      .button {
        & + .button {
          @apply mt-4;

          @screen md {
            @apply mt-0 ml-6;
          }
        }
      }
    }

    .customers {
      @apply flex-wrap justify-center gap-x-10 gap-y-4 max-w-[607px] w-auto mt-12;

      .logo {
        @apply justify-center h-6 w-auto;

        picture {
          @apply opacity-50 rounded-none;
        }
      }
    }

    .assets {
      @apply flex-col items-center mt-8;

      @screen md {
        @apply mt-12;
      }

      @screen md {
        @apply flex-row gap-4 items-end justify-center;
      }

      @screen xl {
        @apply max-w-none;
      }

      .integrations {
        @apply hidden;

        @screen xl {
          @apply flex flex-wrap flex-shrink-0 gap-1 h-[132px] w-[132px];

          .integration {
            @apply items-center justify-center h-16 w-16 bg-[#FCFCFC] border border-grey-blue-100 rounded transition-colors duration-600 ease-in-out;

            &:hover {
              background-color: rgba(var(--bg-color), 0.1);
            }

            .logo {
              @apply w-12 rounded-none;
            }
          }
        }
      }

      .badges-and-polls {
        @apply flex-col items-center;

        @screen md {
          @apply flex-shrink-0 items-start max-w-[180px];
        }

        @screen xl {
          @apply max-w-[272px] flex-shrink-0;
        }

        .badges {
          @apply w-auto gap-x-[7.42px];

          @screen md {
            @apply gap-x-[5.29px];
          }

          @screen xl {
            @apply gap-x-2;
          }

          .picture {
            @apply rounded-none;
          }
        }

        .polls {
          @apply hidden;

          @screen md {
            @apply flex flex-col gap-[10.59px] mt-[6.25px];
          }

          @screen xl {
            @apply gap-4 mt-[9px];
          }

          .poll {
            @apply flex-col p-[6px] bg-[#FCFCFC] border-[0.662px] border-grey-blue-100 rounded-[7.941px];

            @screen xl {
              @apply p-[9px] border rounded;
            }

            .title {
              @apply font-inter font-semibold text-grey-blue-900 text-[5.294px] leading-[7.279px] -tracking-[0.032px];

              @screen xl {
                @apply text-[8px] leading-[11px] -tracking-[0.048px];
              }
            }

            .answer {
              @apply relative items-center justify-between h-3 mt-[6.16px] bg-grey-blue-200 rounded-[3.31px] overflow-hidden;

              @screen xl {
                @apply h-[18.1px] mt-[9.31px] rounded-[5px];
              }

              & + .answer {
                @apply mt-[2.92px];

                @screen xl {
                  @apply mt-[4.41px];
                }
              }

              .bar {
                @apply absolute h-full w-0 bg-livestorm-blue-500 z-0;
              }

              .value {
                @apply ml-[3.42px] font-inter font-semibold text-white text-[5.294px] leading-[7.279px] -tracking-[0.032px] z-10;

                @screen xl {
                  @apply  ml-[5.17px] text-[8px] leading-[11px] -tracking-[0.048px];
                }
              }

              .count {
                @apply mr-[2.25px] font-inter font-medium text-grey-blue-600 text-[5.294px] leading-[7.279px] -tracking-[0.032px] z-10;

                @screen xl {
                  @apply mr-[5.09px] text-[8px] leading-[11px] -tracking-[0.048px];
                }

                span {
                  @apply font-bold;
                }
              }
            }

            .button {
              @apply self-start w-auto mt-[6.75px] px-[4.88px] py-[2.25px] bg-grey-blue-100 font-inter font-semibold text-[#C0C0C5] text-[5.294px] leading-[7.279px] -tracking-[0.032px] rounded-[3px];

              @screen xl {
                @apply mt-[10.2px] text-[8px] leading-[11px] -tracking-[0.048px] rounded-[4.53px];
              }
            }
          }
        }
      }

      .video {
        @apply relative flex-shrink-0 justify-center h-[284px] mt-8 border border-grey-blue-100 rounded overflow-hidden;

        @screen md {
          @apply max-w-[328px] mt-0;
        }

        @screen xl {
          @apply h-[388px] w-[440px] max-w-none;
        }

        &::before {
          @apply absolute inset-0 bg-white/60 z-10 content-[''];
        }

        video {
          @apply self-start h-full rounded-none;
        }

        .button {
          @apply absolute left-1/2 top-1/2 items-center justify-center h-14 w-14 px-0 py-0 bg-livestorm-blue-700/30 font-normal text-white text-origin leading-7 backdrop-blur-[2px] rounded-4xl transform -translate-x-1/2 -translate-y-1/2 z-20 transition-colors duration-600 ease-in-out;

          &:hover {
            @apply bg-livestorm-blue-700/100;
          }

          .icon {
            @apply text-xl leading-none;
          }
        }
      }

      .questions {
        @apply hidden;

        @screen md {
          @apply flex flex-col flex-shrink-0 max-w-[180px] p-[6.96px] bg-[#FCFCFC] border-[0.765px] border-grey-blue-100 rounded-[9.184px] overflow-hidden;
        }

        @screen xl {
          @apply max-w-[235px] p-[9.1px] border rounded;
        }

        .head {
          @apply items-center justify-between p-[6.652px];

          @screen xl {
            @apply p-[8.69px];
          }

          .h6 {
            @apply font-semibold font-inter text-grey-blue-900 text-[8.418px] leading-[13.O1px] -tracking-[0.007px];

            @screen xl {
              @apply font-[11px] leading-[17px] -tracking-[0.009px];
            }
          }
        }

        .body {
          @apply flex-col;

          .question {
            @apply flex-col px-[6.12px] py-[9.19px] border-t-[0.416px] border-grey-blue-100;

            @screen xl {
              @apply px-2 py-3 border-t-[0.543px];
            }

            &:first-child {
              @apply border-t-0;
            }

            .upvotes {
              @apply flex-col flex-shrink-0 items-center justify-center h-[16.629px] w-[16.629px] border-[0.383px] border-[#C8D3DA] rounded-[3.326px];

              @screen xl {
                @apply h-[21.729px] w-[21.729px] border-[0.5px] rounded-[4.346px];
              }

              .up {
                @apply w-auto;

                svg {
                  @apply h-[2.49px] w-auto;

                  @screen xl {
                    @apply h-[3.25px];
                  }
                }
              }

              p {
                @apply mt-[2.49px] font-inter font-semibold text-black text-center text-[4.573px] leading-none tracking-[0.002px];

                @screen xl {
                  @apply mt-[3.26px] text-[5.975px] tracking-[0.003px];
                }
              }
            }

            .picture {
              @apply flex-shrink-0 w-auto ml-[4.99px];

              @screen xl {
                @apply ml-[6.52px];
              }
            }

            .message {
              @apply flex-col w-auto ml-[3.33px];

              @screen xl {
                @apply ml-[4.35px];
              }

              p {
                @apply font-semibold font-inter text-[6.888px] leading-[6.652px] -tracking-[0.002px];

                @screen xl {
                  @apply text-[9px] leading-[8.691px] -tracking-[0.003px];
                }

                & + p {
                  @apply mt-[3.06px] font-normal text-grey-blue-900 leading-[10.714px] opacity-70;

                  @screen xl {
                    @apply mt-1 leading-[14px];
                  }
                }
              }
            }

            .actions {
              @apply flex-col items-center justify-between w-auto mt-0;

              svg {
                @apply h-[6.65px] w-auto;

                @screen xl {
                  @apply h-[8.69px];
                }
              }
            }

            .answer {
              @apply mt-[3.33px];

              @screen xl {
                @apply mt-[4.35px];
              }

              .avatar {
                @apply flex-shrink-0 ml-[21.62px] rounded-full;

                @screen xl {
                  @apply ml-[28.25px];
                }
              }

              p {
                @apply ml-[3.33px] px-[4.99px] bg-grey-blue-100 font-inter text-grey-blue-900 text-[6.888px] leading-[13.303px] -tracking-[0.1px] rounded-[2.079px] opacity-70;

                @screen xl {
                  @apply ml-[4.35px] px-[6.52px] text-[9px] leading-[17.383px] rounded-[2.716px];
                }
              }
            }
          }
        }

        .footer {
          textarea {
            @apply h-auto p-[4.98px] bg-white border-[0.383px] border-[#C8D3DA] font-inter text-[6.89px] text-grey-blue-600 leading-[9.98px] rounded-[3.326px];

            @screen xl {
              @apply p-[6.51px] border-[0.5px] text-[9px] leading-[13.04px] rounded-[4.35px];
            }
          }
        }
      }

      .reviews {
        @apply hidden;

        @screen xl {
          @apply flex flex-col flex-shrink-0 items-center gap-4 max-w-[188px] p-[28px] bg-[#FCFCFC] border border-grey-blue-100 rounded overflow-hidden;

          .head {
            @apply flex-col gap-2 items-center;

            .stars {
              @apply justify-between w-20;

              .star {
                @apply relative w-4 h-4 bg-white;
                mask-image: url(@/assets/svg/star-rounded.svg);
                mask-repeat: no-repeat;
                mask-size: 16px;

                &::after {
                  @apply absolute h-4 bg-sandstorm-yellow-600 content-[''];
                  width: calc(var(--rating) * 100%);
                }
              }
            }

            :deep(.text) {
              @apply font-sans font-medium text-base text-winter-green-900 leading-[120%];

              strong {
                @apply font-medium;
              }
            }
          }

          .body {
            @apply justify-center;

            .medal {
              @apply rounded-none;
            }
          }
        }
      }
    }
  }

  .benefits {
    @apply flex-col items-center gap-8 mt-20;

    .h2 {
      @apply font-medium text-2xl leading-8;

      @screen mdminus {
        @apply text-[28px] leading-[34px];
      }

      @screen mdplus {
        @apply text-3xl leading-10;
      }
    }

    .list {
      @apply flex-col gap-10;

      @screen md {
        @apply flex-row flex-wrap;
      }

      .benefit {
        @apply flex-col;

        @screen md {
          width: calc((100% - 40px) / 2);
        }

        @screen xl {
          width: calc((100% - 120px) / 4);
        }

        picture {
          @apply rounded-none;
        }

        .h3 {
          @apply mt-4 text-xl leading-7;

          @screen mdminus {
            @apply text-2xl leading-8;
          }

          @screen mdplus {
            @apply text-2xl leading-8;
          }

          & + p {
            @apply mt-1 text-grey-blue-600;
          }
        }
      }
    }
  }

  .industries {
    @apply mt-26;

    @screen md {
      @apply mt-28;
    }

    .inner {
      @apply flex-col bg-white rounded-[40px] shadow-light4 overflow-hidden;

      .head {
        @apply flex-col items-center pt-8;

        @screen md {
          @apply pt-14;
        }

        .h2 {
          @apply px-6 text-center;

          @screen md {
            @apply px-[88px];
          }
        }

        .menu {
          @apply items-start h-[50px] w-auto px-5 mt-6 overflow-scroll;

          @screen md {
            @apply overflow-hidden;
          }

          .item {
            @apply relative w-auto mx-5 text-grey-blue-600 leading-12 cursor-pointer;

            &.blizzard {
              &::after {
                @apply bg-blizzard-700;
              }
            }

            &.spring-green {
              &::after {
                @apply bg-spring-green-700;
              }
            }

            &.winter-green {
              &::after {
                @apply bg-winter-green-700;
              }
            }

            &::after {
              @apply absolute bottom-0 left-1/2 h-[2px] w-0 bg-winter-green-900 content-[''] transition-all duration-600 ease-in-out;
            }

            &.active {
              &::after {
                @apply left-0 w-full;
              }

              p {
                @apply font-medium text-winter-green-900 cursor-default;
              }
            }

            p {
              @apply font-normal text-origin text-grey-blue-600 leading-12;
            }
          }

          .button {
            @apply flex-shrink-0 mx-5 px-0 font-normal text-livestorm-blue-700;

            .icon {
              @apply text-livestorm-blue-700;
            }
          }
        }
      }

      .body {
        @apply mt-8;

        .slider {
          @apply transform transition-transform duration-800 ease-in-out;

          .item {
            @apply flex-shrink-0 flex-col w-full;

            @screen xl {
              @apply h-[560px];
            }

            &.blizzard {
              .main {
                .content {
                  .title {
                    &:hover {
                      .arrow {
                        @apply bg-blizzard-700 !important;
                      }
                    }

                    .h3 {
                      :deep(strong) {
                        @apply text-blizzard-700;
                      }
                    }

                    .arrow {
                      @apply border-blizzard-700 !important;

                      .icon {
                        @apply text-blizzard-700;
                      }
                    }
                  }
                }
              }

              .aside {
                @apply bg-blizzard-100;

                .key-number {
                  &::before {
                    @apply bg-blizzard-700;
                  }
                }
              }
            }

            &.spring-green {
              .main {
                .content {
                  .title {
                    &:hover {
                      .arrow {
                        @apply bg-spring-green-700 !important;
                      }
                    }

                    .h3 {
                      :deep(strong) {
                        @apply text-spring-green-700;
                      }
                    }

                    .arrow {
                      @apply border-spring-green-700 !important;

                      .icon {
                        @apply text-spring-green-700;
                      }
                    }
                  }
                }
              }

              .aside {
                @apply bg-spring-green-100;

                .key-number {
                  &::before {
                    @apply bg-spring-green-700;
                  }
                }
              }
            }

            &.winter-green {
              .main {
                .content {
                  .title {
                    &:hover {
                      .arrow {
                        @apply bg-winter-green-700 !important;
                      }
                    }

                    .h3 {
                      :deep(strong) {
                        @apply text-winter-green-700;
                      }
                    }

                    .arrow {
                      @apply border-winter-green-700 !important;

                      .icon {
                        @apply text-winter-green-700;
                      }
                    }
                  }

                }
              }

              .aside {
                @apply bg-winter-green-100;

                .key-number {
                  &::before {
                    @apply bg-winter-green-700;
                  }
                }
              }
            }

            &.full {
              @screen xl {
                @apply items-stretch;
              }

              .main {
                @screen xl {
                  @apply h-full;
                }

                .content {
                  @screen xl {
                    @apply my-auto;
                  }

                  .quote {
                    @apply line-clamp-4;
                  }
                }
              }
            }

            .main {
              @apply relative flex-col px-6 pb-8;

              @screen xl {
                @apply pb-18;
              }

              @screen md {
                @apply px-12;
              }

              @screen xl {
                @apply flex-row flex-wrap justify-between px-16;
              }

              .illustation {
                @apply w-auto mx-auto mb-6;

                @screen xl {
                  @apply absolute right-16 top-0 max-w-[620px] mx-0 mb-0;
                }

                picture {
                  @apply rounded-[6px];

                  @screen md {
                    @apply rounded;
                  }
                }
              }

              .content {
                @apply flex-col;

                @screen xl {
                  @apply order-first max-w-[484px];
                }

                .title {
                  @apply flex items-center justify-between;

                  @screen xl {
                    @apply mt-6;
                  }

                  &:hover {
                    .arrow {
                      @apply bg-winter-green-900;

                      .icon {
                        @apply text-white;
                      }
                    }
                  }

                  & + p {
                    @apply mt-4 text-grey-blue-600;
                  }

                  .h3 {
                    @apply text-xl leading-7;

                    @screen mdminus {
                      @apply text-2xl leading-8;
                    }

                    @screen mdplus {
                      @apply text-2xl leading-8;
                    }
                  }

                  .arrow {
                    @apply flex-shrink-0 items-center justify-center h-8 w-8 border border-winter-green-900 rounded-full transition-colors duration-500 ease-in-out;

                    .icon {
                      @apply transition-colors duration-500 ease-in-out;
                    }
                  }
                }

                .quote {
                  @apply line-clamp-4;
                }

                .metadata {
                  @apply flex-col mt-4;

                  @screen md {
                    @apply flex-row flex-wrap items-center justify-between;
                  }

                  .author {
                    @apply w-auto;

                    .picture {
                      @apply flex-shrink-0 rounded-full transform translate-y-[10px];

                      @screen md {
                        @apply transform-none;
                      }
                    }

                    .name {
                      @apply flex-col w-auto ml-3;

                      p {
                        @apply font-medium text-base leading-6;

                        & + p {
                          @apply font-normal text-sm text-grey-blue-600 leading-5;
                        }
                      }
                    }
                  }

                  .logo {
                    @apply ml-14 rounded-none;
                  }
                }

                .button {
                  @apply self-start h-[50px] mt-4 px-0;
                }
              }
            }

            .aside {
              @apply flex-col gap-4 px-6 py-8;

              @screen md {
                @apply flex-row flex-wrap px-12 pt-16 pb-12;
              }

              @screen xl {
                @apply h-full px-16;
              }

              .key-number {
                @apply relative flex-col w-full pl-4;

                @screen md {
                  @apply max-w-[214px];
                }

                &::before {
                  @apply absolute left-0 top-0 h-full w-1 content-[''] rounded-[2px];
                }

                p {
                  @apply font-medium text-xl leading-[normal];

                  & + p {
                    @apply mb-4 font-normal text-base text-grey-blue-600 leading-[normal];
                  }
                }

                .logo {
                  @apply mt-auto rounded-none;
                }
              }
            }
          }
        }
      }
    }
  }

  .use-cases {
    @apply relative flex-col mt-26;

    @screen md {
      @apply mt-40;
    }

    .h2 {
      :deep(strong) {
        @apply text-blizzard-600;
      }

      & + p {
        @apply mt-4 text-grey-blue-600;
      }
    }

    .actions {
      @apply flex-col gap-4 mt-6;

      @screen md {
        @apply flex-row flex-wrap;
      }
    }

    .menu {
      @apply relative flex-col h-[1012px] gap-4 mt-8 overflow-hidden;

      @screen md {
        @apply flex-row flex-wrap justify-between;
      }

      @screen xl {
        @apply h-[435px] overflow-visible;
      }

      &::after {
        @apply absolute bottom-0 left-0 right-0 h-[275px] w-full bg-gradient-to-b from-white/0 from-[0%] to-grey-blue-100/100 to-[91.15%] content-[''] z-20;

        @screen xl {
          @apply content-none;
        }
      }

      .item {
        @apply relative flex flex-shrink-0 justify-center rounded-[40px] overflow-hidden transition-all duration-600 ease-in-out;

        @screen xl {
          @apply w-20;
        }

        &.active {
          @screen xl {
            @apply w-[894px];
          }

          .head {
            @apply opacity-0;
          }

          .body {
            @apply opacity-100;

            .inner {
              @apply opacity-100 translate-y-0;
            }

            .button {
              @apply opacity-100 translate-y-0;
            }
          }
        }

        picture {
          @apply rounded-none z-0;
        }

        .head {
          @apply hidden;

          @screen xl {
            @apply absolute inset-0 flex backdrop-blur-sm z-10 transition-opacity duration-600 ease-in-out;
          }

          .h3 {
            @apply flex-shrink-0 pt-10 pr-[26px] font-medium text-white text-[28px] leading-none transform -scale-100;
            writing-mode: vertical-rl;
          }
        }

        .body {
          @apply absolute inset-0 bg-gradient-to-b from-transparent from-[33.79%] to-black/30 to-[71.26%] z-20 transition-opacity duration-600 delay-100 ease-in-out;

          @screen xl {
            @apply opacity-0;
          }

          .inner {
            @apply relative flex-col mt-auto pr-6 pb-8 pl-8;

            @screen md {
              @apply pr-8;
            }

            @screen xl {
              @apply pr-10 pb-10 pl-10 opacity-0 transform translate-y-20 transition-transform duration-600 ease-in-out;
            }

            p {
              @apply font-medium text-white text-[24px] leading-[normal];

              @screen md {
                @apply w-[375px] text-[28px];
              }

              @screen xl {
                @apply w-[575px];
              }

              & + p {
                @apply hidden;

                @screen md {
                  @apply flex mt-3 font-normal text-origin leading-7;
                }
              }
            }
          }

          .button {
            @apply absolute bottom-6 right-6 items-center justify-center h-10 w-10 bg-white rounded-full;

            @screen md {
              @apply bottom-10 right-8;
            }

            @screen xl {
              @apply right-10 opacity-0 transform translate-y-20 transition-transform duration-600 ease-in-out;
            }
          }
        }
      }
    }

    .button {
      &.text {
        @apply absolute bottom-0 w-full z-30;

        @screen xl {
          @apply static h-[50px] w-auto ml-auto mt-8 px-0 z-0;
        }
      }
    }
  }

  .showcased-features {
    @apply relative flex-col mt-26;

    @screen md {
      @apply mt-40;
    }

    @screen xl {
      @apply h-[1067px];
    }

    .h2 {
      & + p {
        @apply mt-4 text-grey-blue-600;
      }

      @screen xl {
        @apply z-10;
      }
    }

    .actions {
      @apply flex-col gap-4 mt-6;

      @screen md {
        @apply flex-row flex-wrap;
      }

      @screen xl {
        @apply z-10;
      }
    }

    .list {
      @apply mt-12;

      @screen xl {
        @apply absolute top-[158px] left-0 right-0 bottom-0 mt-0 z-0;
      }

      .outer {
        @apply flex-col gap-10;

        @screen md {
          @apply flex-row flex-wrap;
        }

        @screen xl {
          @apply relative;
        }

        .item {
          @apply bg-white rounded-2xl overflow-hidden;
          box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.02), 0px 5px 5px 0px rgba(0, 0, 0, 0.02), 0px 11px 6px 0px rgba(0, 0, 0, 0.01), 0px 19px 8px 0px rgba(0, 0, 0, 0.00), 0px 29px 8px 0px rgba(0, 0, 0, 0.00);

          @screen md {
            width: calc((100% - 40px) / 2);
          }

          @screen xl {
            @apply absolute max-w-[416px];
          }

          &.registration-and-company-page {
            @apply h-[278px];

            @screen md {
              @apply h-[327px];
            }

            @screen xl {
              @apply absolute left-6 top-[151.37px] h-[359px];
            }

            &:hover {
              .inner {
                .branded {
                  @apply opacity-100;
                }
              }
            }

            .inner {
              @apply relative;

              .branded,
              .neutral {
                @apply absolute inset-0 flex-col w-full z-0 overflow-hidden;

                .h5 {
                  @apply flex flex-shrink-0 min-w-[295px] mt-6 mx-6;

                  @screen xl {
                    @apply min-w-[325px] mt-10 mx-10;
                  }
                }

                .background {
                  @apply flex flex-shrink-0 mt-4 ml-6 rounded;

                  @screen md {
                    @apply mt-6;
                  }

                  @screen xl {
                    @apply mt-8 ml-10;
                  }
                }
              }

              .branded {
                @apply bg-winter-green-700 z-10;

                @screen xl {
                  @apply w-0 opacity-0 transition-opacity duration-600 ease-in-out;
                }

                .h5 {
                  @apply text-white;
                }
              }
            }
          }

          &.ai-transcription {
            @apply h-[327px];

            @screen xl {
              @apply absolute left-6 top-[526.37px] h-[314px];
            }

            &:hover {
              .inner {
                &::after {
                  @screen xl {
                    @apply max-h-14;
                  }
                }

                .body {
                  .thread {
                    @screen xl {
                      @apply max-h-[201px];
                    }

                    .post {
                      @screen xl {
                        @apply opacity-100;
                      }
                    }
                  }
                }
              }
            }

            .inner {
              @apply relative flex-col;

              &::after {
                @apply absolute bottom-14 h-16 w-full bg-gradient-to-b from-white/0 to-white/100 to-[71.31%] content-[''] z-10;

                @screen xl {
                  @apply max-h-0 transition-all duration-600 ease-in-out;
                }
              }

              .body {
                @apply items-center h-full px-6 pt-6 z-0;

                @screen xl {
                  @apply px-10 pt-10;
                }

                .thread {
                  @apply flex-col gap-3 p-5 bg-[#FCFCFC] border border-grey-blue-200 rounded overflow-hidden;

                  @screen md {
                    @apply max-h-[235px];
                  }

                  @screen xl {
                    @apply max-h-[89px] transition-all duration-600 ease-in-out;
                  }

                  .post {
                    @apply gap-2;

                    @screen xl {
                      @apply opacity-0 transition-opacity duration-600 ease-in-out;
                    }

                    &:first-child {
                      @screen xl {
                        @apply opacity-100;
                      }
                    }

                    .avatar {
                      @apply rounded-full;
                    }

                    .content {
                      @apply flex-col gap-1;

                      p {
                        @apply font-bold text-[11px] text-black leading-none;

                        & + p {
                          @apply font-normal text-grey-blue-600 leading-normal;
                        }
                      }
                    }
                  }
                }
              }

              .footer {
                @apply flex-shrink-0 items-center justify-between mt-auto px-6 pb-6 z-20;

                @screen xl {
                  @apply px-10 pb-10;
                }

                .equalizer {
                  @apply items-center gap-[11px] w-auto;

                  .wrapper {
                    @apply gap-1 w-auto;

                    .bar {
                      @apply flex-col-reverse gap-[2px] h-[18px] w-2;

                      .line {
                        @apply h-[2px] w-full bg-sunburst-red-300 rounded-[1px];
                      }
                    }
                  }

                  .icon {
                    @apply hidden;

                    @screen xl {
                      @apply flex w-auto;
                    }
                  }
                }
              }
            }
          }

          &.calendar-integrations {
            @apply h-[348px];

            @screen md {
              @apply h-[323px];
            }

            @screen xl {
              @apply absolute left-[888px] top-0 h-[491px];
            }

            &:hover {
              .inner {
                .body {
                  .slider {
                    .slide {
                      .illustration {
                        &.first {
                          @screen xl {
                            @apply -rotate-2;
                          }
                        }

                        &.last {
                          @screen xl {
                            @apply rotate-2;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            .inner {
              @apply flex-col;

              .body {
                @apply mt-[33px];

                @screen md {
                  @apply mt-6;
                }

                @screen xl {
                  @apply mt-12;
                }

                .slider {
                  @apply relative;

                  .slide {
                    @screen xl {
                      @apply px-12;
                    }

                    .illustration {
                      @apply rounded-none transform transition-transform duration-600 ease-in-out;

                      &.first {
                        @apply -rotate-2;

                        @screen xl {
                          @apply rotate-0;
                        }
                      }

                      &.last {
                        @apply rotate-2;

                        @screen xl {
                          @apply rotate-0;
                        }
                      }
                    }
                  }
                }
              }

              .footer {
                @apply items-center justify-between mt-auto px-6 pb-6;

                @screen xl {
                  @apply px-10 pb-10;
                }

                .menu {
                  @apply hidden;

                  @screen xl {
                    @apply flex gap-3 w-auto;
                  }

                  .integration {
                    @apply items-center justify-center h-auto w-auto p-1 bg-white border border-grey-blue-100 rounded-[8px] shadow-light3 cursor-pointer transition-colors duration-600 ease-in-out;

                    &.active {
                      @apply border-livestorm-blue-400;
                    }

                    picture {
                      @apply rounded-none;
                    }
                  }
                }
              }
            }
          }

          &.saml-sso {
            @apply h-[250px] overflow-visible;

            @screen md {
              @apply h-[323px];
            }

            @screen xl {
              @apply absolute left-[888px] top-[507px] h-[290px];
            }

            .inner {
              @apply relative flex-col;

              .body {
                @apply flex-col justify-center h-[136px] px-6 mt-12 overflow-hidden;

                @screen md {
                  @apply mt-18;
                }

                @screen xl {
                  @apply px-10 mt-12;
                }

                .form {
                  @apply flex-col gap-4 h-auto;

                  .fieldset {
                    @apply flex-col gap-2;

                    .label {
                      @apply font-inter font-medium text-sm text-black leading-none;
                    }

                    .input {
                      @apply gap-2 p-4 bg-[#FCFCFC] border border-grey-blue-200 rounded-[10px];

                      p {
                        @apply ml-2 text-grey-blue-600 text-base leading-none;

                        &.placeholder {
                          @apply text-grey-blue-500;
                        }
                      }
                    }
                  }

                  .submit {
                    @apply justify-center p-4 bg-livestorm-blue-800 font-inter font-bold text-base text-center text-white leading-none rounded-[10px];

                    .icon {
                      @apply mr-2;
                    }
                  }
                }
              }

              .footer {
                @apply px-6 pb-6 mt-auto;

                @screen xl {
                  @apply px-10 pb-10;
                }
              }

              .illustration {
                @apply absolute -bottom-[30px] -right-[25px] rounded-none z-10;

                @screen md {
                  @apply -bottom-[26px] -right-[24px];
                }

                @screen xl {
                  @apply -bottom-[38px] -right-[25px];
                }
              }
            }
          }

          &.custom-branding {
            @apply h-[297px];

            @screen md {
              @apply h-[335px] pb-0;
            }

            @screen xl {
              @apply absolute left-[456px] top-[87.36px] h-[364px];
            }

            &:hover {
              .inner {
                .body {
                  .slider {
                    .slide {
                      .background {
                        @apply opacity-100;
                      }
                    }
                  }
                }

                .footer {
                  .h5 {
                    @apply text-white;
                  }
                }
              }
            }

            .inner {
              @apply relative flex-col;

              .body {
                @apply absolute inset-0;

                .slider {
                  @apply relative flex-nowrap;

                  .slide {
                    @apply absolute inset-0 justify-center px-6 opacity-0 z-0;

                    @screen xl {
                      @apply px-10;
                    }

                    &:last-child {
                      @apply opacity-100;
                    }

                    .background {
                      @apply absolute inset-0 rounded-none;

                      @screen xl {
                        @apply opacity-0 transition-opacity duration-600 ease-in-out;
                      }
                    }

                    .layout {
                      @apply mt-6 rounded-none;

                      @screen md {
                        @apply mt-12;
                      }

                      @screen xl {
                        @apply mt-10;
                      }
                    }
                  }
                }
              }

              .footer {
                @apply items-center justify-between mt-auto pb-6 px-6 z-10;

                @screen md {
                  @apply pb-10 px-10;
                }

                .h5 {
                  @apply flex-shrink-0 text-white;

                  @screen xl {
                    @apply text-winter-green-900 transition-colors duration-600 ease-in-out;
                  }
                }

                .menu {
                  @apply hidden;

                  @screen xl {
                    @apply flex gap-1 w-auto;
                  }

                  .dot {
                    @apply items-center justify-center h-5 w-5;

                    &::before {
                      @apply flex h-4 w-4 border-2 rounded-full cursor-pointer opacity-70 transition-opacity duration-500 ease-in-out content-[''];
                    }

                    &.active {
                      &::before {
                        @apply opacity-100 cursor-default;
                      }
                    }

                    &.grey-blue {
                      &::before {
                        @apply bg-grey-blue-200 border-grey-blue-500;
                      }
                    }

                    &.live-red {
                      &::before {
                        @apply bg-live-red-100 border-live-red-500;
                      }
                    }

                    &.spring-green {
                      &::before {
                        @apply bg-spring-green-200 border-spring-green-500;
                      }
                    }

                    &.sunburst-red {
                      &::before {
                        @apply bg-sunburst-red-100 border-sunburst-red-500;
                      }
                    }
                  }
                }
              }
            }
          }

          &.marketing-automation-and-crm-integrations {
            @apply h-[383px];

            @screen md {
              @apply h-[335px];
            }

            @screen xl {
              @apply absolute left-[456px] top-[467.36px] h-[441px];
            }

            .inner {
              @apply relative flex-col gap-4;

              &::after {
                @apply absolute bottom-8 left-0 right-0 h-[110px] w-full bg-gradient-to-b from-white/0 to-white/100 to-[29.72%] content-[''] z-10;

                @screen md {
                  @apply bottom-[30px];
                }

                @screen xl {
                  @apply bottom-10;
                }
              }

              .body {
                @apply relative h-full px-6 z-0;

                @screen xl {
                  @apply px-10;
                }

                .automations {
                  @apply absolute;
                  width: calc(100% - 48px);

                  @screen xl {
                    width: calc(100% - 80px);
                  }
                }

                .participants {
                  @apply relative flex-col mt-auto rounded z-10;
                  --via: #EAEEF1;

                  &::before {
                    @apply absolute -left-px -top-px content-[''] rounded transition-colors duration-600 ease-in-out z-0;
                    background: linear-gradient(90deg, #EAEEF1 33%, var(--via) 50%, #EAEEF1 66%);
                    height: calc(100% + 2px);
                    width: calc(100% + 2px);
                  }

                  &::after {
                    @apply absolute left-0 top-0 h-full w-full bg-[#FCFCFC] content-[''] rounded z-10;
                  }

                  .participant {
                    @apply items-center px-4 py-[10px] z-20;

                    .avatar {
                      @apply rounded-full;
                    }
                  }

                  .name {
                    @apply w-24 ml-2 font-inter font-medium text-grey-blue-800 text-[11px] leading-[1.2] -tracking-[0.004px];
                  }

                  .email {
                    @apply w-28 ml-4 font-inter text-grey-blue-800 text-[11px] leading-[1.2] -tracking-[0.004px];
                  }

                  .country {
                    @apply ml-auto h-auto w-[10.382px] rounded-[1px];

                    @screen xl {
                      @apply w-3;
                    }
                  }
                }
              }

              .footer {
                @apply px-6 pb-6 mt-auto z-20;

                @screen xl {
                  @apply px-10 pb-10;
                }
              }
            }
          }
        }
      }
    }
  }

  .all-features {
    @apply flex-col items-center mt-20 overflow-hidden;

    .inner {
      @apply justify-center;

      .h3 {
        @apply text-center text-3xl leading-10;
      }
    }

    .list {
      @apply flex-col items-center gap-3 my-8;

      .row {
        @apply max-w-none w-auto gap-3;

        .feature {
          @apply flex-shrink-0 w-auto px-5 py-3 bg-white text-grey-blue-600 leading-7 rounded-2xl;

          &.highlighted {
            @apply bg-live-red-200 text-live-red-700;

            &.blizzard {
              @apply bg-blizzard-200 text-blizzard-700;
            }

            &.grey-blue {
              @apply bg-grey-blue-200 text-grey-blue-700;
            }

            &.live-red {
              @apply bg-live-red-200 text-live-red-700;
            }

            &.livestorm-blue {
              @apply bg-livestorm-blue-200 text-livestorm-blue-700;
            }

            &.sandstorm-yellow {
              @apply bg-sandstorm-yellow-200 text-sandstorm-yellow-700;
            }

            &.sirocco {
              @apply bg-sirocco-200 text-sirocco-800;
            }

            &.spring-green {
              @apply bg-spring-green-200 text-spring-green-700;
            }

            &.sunburst-red {
              @apply bg-sunburst-red-200 text-sunburst-red-700;
            }

            &.winter-green {
              @apply bg-winter-green-200 text-winter-green-800;
            }
          }
        }
      }
    }

    .action {
      @apply justify-end;

      @screen xl {
        @apply mt-6;
      }

      .button {
        @apply h-[50px] px-0 text-winter-green-900;
      }
    }
  }

  .key-numbers {
    @apply relative flex-col mt-26;

    @screen md {
      @apply mt-30 h-[1577px];
    }

    @screen xl {
      @apply h-[921px];
    }

    .h2 {
      @apply text-white text-4xl leading-14 z-10;

      @screen md {
        @apply max-w-[620px];
      }

      @screen mdminus {
        @apply font-medium text-3xl leading-10;
      }

      @screen mdplus {
        @apply font-normal text-5xl leading-16;
      }

      & + p {
        @apply mt-4 text-white z-10;

        @screen md {
          @apply max-w-[620px];
        }
      }
    }

    .actions {
      @apply flex-col gap-4 mt-6 z-10;

      @screen md {
        @apply flex-row flex-wrap gap-6 w-auto;
      }

      .button {
        .icon {
          @apply mr-2;
        }
      }
    }

    .reinsurance {
      @apply justify-center mt-12;

      @screen xl {
        @apply absolute inset-0 mt-0;
      }

      .inner {
        @apply relative flex-col gap-4;

        @screen md {
          @apply gap-0 max-w-[1060px];
        }

        @screen xl {
          @apply h-[921px];
        }

        .key-number {
          @apply relative flex-col justify-center h-[204px] p-8 bg-[#152C32] border border-white/[0.08] rounded-2xl overflow-hidden;
          background: linear-gradient(180deg, #13252A 0%, #13262C 19.2%, #13272E 64.08%, #172D34 79.76%, #1A3138 100%);

          @screen md {
            @apply absolute;
            max-width: calc((100% - 16px) / 2);
          }

          @screen xl {
            @apply max-w-[308px];
          }

          &:hover {
            &::after {
              @apply opacity-100;
            }
          }

          &::after {
            @apply absolute h-[282px] w-[282px] opacity-0 content-[''] transform -translate-x-1/2 -translate-y-1/2 rounded-full z-20 transition-opacity duration-600 ease-in-out;
            background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.00) 100%);
            left: var(--x, 50%);
            top: var(--y, 50%);
          }

          &:nth-child(1) {
            @screen md {
              @apply left-0 top-[72px];
            }

            @screen xl {
              @apply left-0 top-[368px];
            }
          }

          &:nth-child(2) {
            @screen md {
              @apply left-0 top-[292px];
            }

            @screen xl {
              @apply left-0 top-[588px];
            }
          }

          &:nth-child(3) {
            @screen md {
              @apply right-0 top-[448px];
            }

            @screen xl {
              @apply right-0 top-[448px];
            }
          }

          &:nth-child(4) {
            @screen md {
              @apply right-0 top-[668px];
            }

            @screen xl {
              @apply right-0 top-[668px];
            }
          }

          .illustration {
            @apply absolute right-0 top-0 rounded-none z-0;
          }

          p {
            @apply font-bold text-winter-green-100 text-5xl leading-[normal] z-10;

            & + p {
              @apply mt-1 font-normal text-white text-origin leading-7;
            }
          }
        }

        .uptime {
          @apply relative h-[113px] bg-[#152C32] border border-white/[0.08] rounded-2xl overflow-hidden;

          @screen md {
            @apply absolute left-0 top-[512px];
            max-width: calc((100% - 16px) / 2);
          }

          @screen xl {
            @apply top-[808px] max-w-[308px];
          }

          &:hover {
            &::after {
              @apply opacity-100;
            }
          }

          &::after {
            @apply absolute h-[282px] w-[282px] opacity-0 content-[''] transform -translate-x-1/2 -translate-y-1/2 rounded-full z-20 transition-opacity duration-600 ease-in-out;
            background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.00) 100%);
            left: var(--x, 50%);
            top: var(--y, 50%);
          }

          .inner {
            @apply relative h-auto p-8;

            p {
              @apply relative pl-6 font-medium text-2xl text-white leading-[normal];

              span {
                @apply font-normal text-origin;
              }

              &::before {
                @apply absolute left-0 top-1/2 flex h-3 w-3 -mt-[6px] bg-spring-green-700 border border-white rounded-full content-[''];
                filter: drop-shadow(0px 0px 8px rgba(23, 166, 121, 0.60))
              }
            }

            .status {
              @apply absolute -bottom-[35px] -left-px items-end gap-1 max-w-none w-auto;

              .availability {
                @apply h-[69px] w-[7px] bg-winter-green-800/30 rounded-[2px];

                &:nth-child(25) {
                  @apply h-[62px];
                }
              }
            }
          }
        }

        .recommended-by {
          @apply relative flex-col justify-between h-[160px] p-8 bg-[#152C32] border border-white/[0.08] rounded-2xl overflow-hidden;

          @screen md {
            @apply absolute left-0 top-[641px];
            max-width: calc((100% - 16px) / 2);
          }

          @screen xl {
            @apply left-[324px] top-[328px] max-w-[412px] h-[128px];
          }

          &:hover {
            &::after {
              @apply opacity-100;
            }
          }

          &::after {
            @apply absolute h-[282px] w-[282px] opacity-0 content-[''] transform -translate-x-1/2 -translate-y-1/2 rounded-full z-20 transition-opacity duration-600 ease-in-out;
            background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.00) 100%);
            left: var(--x, 50%);
            top: var(--y, 50%);
          }

          .logo {
            @apply rounded-none;

            @screen xl {
              @apply absolute right-6 top-6;
            }
          }

          .data {
            @apply items-center w-auto;

            .graph {
              @apply relative flex-shrink-0 h-12 w-12 mr-6;

              circle {
                @apply stroke-[6px] stroke-[#50686F];

                & + circle {
                  @apply stroke-[#FFFFFF] transform -rotate-90 origin-center;
                  stroke-dasharray: 500;
                  stroke-dashoffset: 500;
                  stroke-linecap: round;
                }
              }
            }

            .values {
              @apply flex-col;

              p {
                @apply font-medium text-white text-3xl leading-7;

                & + p {
                  @apply mt-1 font-normal text-origin;
                }
              }
            }
          }
        }

        .g2 {
          @apply relative flex-col justify-between h-[288px] bg-[#152C32] border border-white/[0.08] rounded-2xl overflow-hidden;

          @screen md {
            @apply absolute right-0 top-0;
            max-width: calc((100% - 16px) / 2);
          }

          @screen xl {
            @apply top-0 max-w-[308px];
          }

          &:hover {
            &::after {
              @apply opacity-100;
            }
          }

          &::after {
            @apply absolute h-[282px] w-[282px] opacity-0 content-[''] transform -translate-x-1/2 -translate-y-1/2 rounded-full z-20 transition-opacity duration-600 ease-in-out;
            background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.00) 100%);
            left: var(--x, 50%);
            top: var(--y, 50%);
          }

          .inner {
            @apply relative h-full w-full;

            .badge {
              @apply absolute h-auto w-auto transform -translate-x-1/2 -translate-y-1/2 transition-none;

              /* Center */
              &:nth-child(1) {
                @apply left-1/2 top-1/2 z-10;
              }

              /* Top right */
              &:nth-child(2) {
                @apply opacity-90 z-0;
                left: calc(50% + 67.035px);
                top: calc(50% - 62.79px);
              }

              /* Bottom left */
              &:nth-child(3) {
                @apply z-0;
                left: calc(50% - 82.965px);
                top: calc(50% + 50.22px);
              }

              /* Bottom right */
              &:nth-child(4) {
                @apply opacity-60 blur-[2px] z-0;
                left: calc(50% + 43.035px);
                top: calc(50% + 53.22px);
              }

              /* Top left */
              &:nth-child(5) {
                @apply left-[100.5px] top-[63.46px] opacity-70 blur-[1px] z-0;
                left: calc(50% - 71.965px);
                top: calc(50% - 84.79px);
              }

              picture {
                @apply rounded-none;
              }
            }
          }
        }

        .rating {
          @apply relative flex-col gap-4 items-center justify-center h-[128px] p-8 bg-[#152C32] border border-white/[0.08] rounded-2xl overflow-hidden;

          @screen md {
            @apply absolute right-0 top-[304px];
            max-width: calc((100% - 16px) / 2);
          }

          @screen xl {
            @apply max-w-[308px];
          }

          &:hover {
            &::after {
              @apply opacity-100;
            }
          }

          &::after {
            @apply absolute h-[282px] w-[282px] opacity-0 content-[''] transform -translate-x-1/2 -translate-y-1/2 rounded-full z-20 transition-opacity duration-600 ease-in-out;
            background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.00) 100%);
            left: var(--x, 50%);
            top: var(--y, 50%);
          }

          .stars {
            @apply justify-between w-[113px];

            .star {
              @apply relative w-5 h-5 bg-white;
              mask-image: url(@/assets/svg/star-rounded.svg);
              mask-repeat: no-repeat;
              mask-size: 20px;

              &::after {
                @apply absolute h-5 bg-sandstorm-yellow-600 content-[''];
                width: calc(var(--rating) * 100%);
              }
            }
          }

          .h6 {
            @apply font-normal text-center text-white;

            :deep(strong) {
              @apply font-medium text-white;
            }
          }
        }

        .source {
          @apply self-end w-auto text-xs text-grey-blue-200 leading-4;

          @screen md {
            @apply absolute right-0 top-[888px];
          }

          @screen xl {
            @apply top-[888px];
          }
        }

        .testimonials {
          @apply relative flex-col gap-4 mt-12;

          @screen md {
            @apply absolute left-0 right-0 top-[984px] mt-0;
          }

          @screen xl {
            @apply left-[324px] top-[472px] max-w-[416px];
          }

          .illustration {
            @apply absolute right-0 -top-13 rounded-none;

            @screen md {
              @apply right-[9px] -top-[68.746px];
            }

            @screen xl {
              @apply right-0;
            }
          }

          .body {
            @apply bg-winter-green-800 border border-white/5 rounded-2xl overflow-hidden;

            .slider {
              .item {
                @apply flex-col gap-8 h-auto p-8;

                .company {
                  @apply flex-col gap-3;

                  .logo {
                    @apply rounded-none;

                    :deep(img) {
                      filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(107deg) brightness(106%) contrast(102%);
                    }
                  }

                  .stars {
                    @apply justify-between w-[90.7px];

                    .star {
                      @apply relative w-4 h-4 bg-white;
                      mask-image: url(@/assets/svg/star-rounded.svg);
                      mask-repeat: no-repeat;
                      mask-size: 16px;

                      &::after {
                        @apply absolute h-4 bg-sandstorm-yellow-600 content-[''];
                        width: calc(var(--rating) * 100%);
                      }
                    }
                  }
                }

                .quote {
                  @apply text-white text-base leading-7;
                }

                .author {
                  @apply gap-3 mt-auto;

                  .profile {
                    @apply rounded-full;
                  }

                  .identity {
                    @apply flex-col w-auto;

                    p {
                      @apply font-medium text-white text-base leading-6;

                      & + p {
                        @apply font-normal text-sm leading-5;
                      }
                    }
                  }
                }
              }
            }
          }

          .footer {
            @apply flex-wrap items-center px-2;

            .navigation {
              @apply gap-4 w-auto;

              .icon {
                @apply text-white cursor-pointer;
              }
            }

            .button {
              @apply h-[50px] ml-auto px-0 text-white;

              .icon {
                @apply text-white;
              }
            }
          }
        }
      }
    }
  }

  .see-it-in-action {
    @apply relative flex-col mt-26;

    @screen md {
      @apply mt-40 h-[396px];
    }

    @screen xl {
      @apply h-[485px];
    }

    .background {
      @apply hidden;

      @screen md {
        @apply absolute flex self-center max-w-[1060px] w-auto rounded-[40px] overflow-hidden;
      }

      picture {
        @apply rounded-none;
      }
    }

    .box {
      @apply flex-col p-8 bg-gradient-to-bl from-white/[.95] from-[1.46%] to-white/[.85] to-[98.35%] border border-white backdrop-blur rounded-[36px];

      @screen md {
        @apply justify-center h-[380px] max-w-[344px] ml-2 my-2 p-6 rounded-4xl;
      }

      @screen xl {
        @apply h-[469px] max-w-[400px] p-10;
      }

      .h2 {
        @apply font-medium text-2xl leading-8;

        @screen mdminus {
          font-size: 28px;
          line-height: 34px;
        }

        @screen mdplus {
          @apply text-3xl leading-10;
        }
      }

      p {
        @apply mt-2 text-grey-blue-600;
      }

      .actions {
        @apply flex-col gap-4 mt-6;

        .button {
          .icon {
            @apply mr-2;
          }
        }
      }
    }

    .illustation {
      @apply hidden;
    }
  }

  .latest-articles-and-guides {
    @apply justify-center pt-26 bg-white;

    @screen md {
      @apply pt-30;
    }

    .inner {
      @apply flex-col;

      @screen lg {
        @apply flex-row flex-wrap justify-between;
      }

      .latest-articles {
        @apply flex-col;

        @screen lg {
          @apply w-span8;
        }

        &.full {
          @screen lg {
            @apply w-span12;
          }
        }

        .h2 {
          @apply font-medium text-2xl leading-8;

          @screen mdminus {
            font-size: 28px;
            line-height: 34px;
          }

          @screen mdplus {
            @apply text-3xl leading-10;
          }
        }

        .articles {
          @apply flex-col mt-6;

          @screen md {
            @apply flex-row flex-wrap;
          }

          .article {
            @apply flex-col mt-6 p-6 border-2 border-grey-blue-200 cursor-pointer rounded-[24px];

            @screen md {
              @apply mt-10;
              width: calc(calc(100% - 2.5rem) / 2);

              &:nth-child(odd) {
                @apply ml-0 mr-5;
              }

              &:nth-child(even) {
                @apply ml-5 mr-0;
              }
            }

            &:hover {
              .button {
                :deep(i.icon-arrow-right) {
                  @apply translate-x-2;
                }
              }
            }

            &:nth-child(1) {
              @apply mt-0;
            }

            &:nth-child(2) {
              @screen md {
                @apply mt-0;
              }
            }

            .category {
              @apply self-start w-auto px-2 py-1 bg-blizzard-100 font-medium text-blizzard-700 text-xs leading-4;
              border-radius: 8px;
            }

            .title {
              @apply my-4 font-medium text-winter-green-900 text-origin leading-7;
            }

            .button {
              @apply self-start h-auto p-0 mt-auto font-medium text-winter-green-900 text-origin leading-7;
            }
          }
        }
      }

      .guides {
        @apply flex-col mt-12;

        @screen lg {
          @apply w-span3 mt-0;
        }

        .h2 {
          @apply font-medium text-2xl leading-8;

          @screen mdminus {
            font-size: 28px;
            line-height: 34px;
          }

          @screen mdplus {
            @apply text-3xl leading-10;
          }
        }

        .items {
          @apply flex-col mt-6;

          @screen md {
            @apply flex-row flex-wrap;
          }

          @screen lg {
            @apply flex-col;
          }

          .item {
            @apply self-start justify-start w-auto mt-2 p-0 font-bold;
            height: 50px;

            @screen md {
              @apply w-1/3;
            }

            @screen lg {
              @apply mt-2 w-auto;
            }

            &:nth-child(1) {
              @apply mt-0;
            }

            &:nth-child(2) {
              @screen md {
                @apply mt-0;
              }

              @screen lg {
                @apply mt-2;
              }
            }

            &:nth-child(3) {
              @screen md {
                @apply mt-0;
              }

              @screen lg {
                @apply mt-2;
              }
            }
          }
        }
      }
    }
  }
}
</style>
